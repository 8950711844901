import { FilterContainer } from '@/components/filters/sections/filter-container';
import { InterestRateSlider } from '@/components/filters/sections/interest-rate-slider';

export interface Range {
  from: number;
  to: number;
}

export interface AprFilterProps {
  selectedAprRange: Range;
  onSelectAprRange: (selectedAprRange: Range) => void;
}

export const InterestRateFilter = ({
  selectedAprRange,
  onSelectAprRange,
}: AprFilterProps) => {
  return (
    <FilterContainer
      value="state"
      title={
        <div className="text-heading-05 text-dark-dark">
          Interest Rate Range
        </div>
      }
    >
      <InterestRateSlider
        selectedInterestRateRange={selectedAprRange}
        onSelectInterestRateRange={onSelectAprRange}
      />
    </FilterContainer>
  );
};
