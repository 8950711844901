import { Table } from '@tanstack/react-table';
import {
  Table as UITable,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { ReactTableHeader } from '@/components/table/react-table-header';
import { DataTablePagination } from '@/components/table/data-table-pagination';
import { ReactTableBody } from '@/components/table/react-table-body';

interface ReactTableBodyProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: Table<any>;
  showSource?: boolean;
}

export const ReactTable = ({ table, showSource }: ReactTableBodyProps) => {
  return (
    <div className="space-y-4">
      <div className="rounded-md">
        <UITable>
          <TableHeader>
            {showSource &&
              table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} colSpan={header.colSpan}>
                        {header.column.columnDef.meta?.source ?? '-'}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
          </TableHeader>
          <ReactTableHeader table={table} />
          <ReactTableBody table={table} />
        </UITable>
      </div>

      <DataTablePagination table={table} />
    </div>
  );
};
