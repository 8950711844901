import {
  ManualProperty,
  Property,
} from '@/pages/admin/property-data/property-table';
import { OwnerDetails } from '@/components/map/property-details/owner-details';
import { SalesPipeline } from '@/pages/crm/lead-stages/types';
import { Owner, OwnerForm } from '@/components/map/property-details/types';
import { isFirstOwnerAvailable, isSecondOwnerAvailable } from '@/model/utils';

interface OwnersProps {
  property?: Property;
  salesPipeline?: SalesPipeline;
  updateSalesPipelineOwner?(params: {
    position: 1 | 2;
    ownerForm: OwnerForm;
  }): Promise<void>;
}

const getFirstOwner = (
  manualProperty?: ManualProperty,
  property?: Property
): Owner => {
  if (property && !manualProperty?.firstCurrentOwnerFullName) {
    return {
      fullName: `${property.firstCurrentOwnerFirstName ?? ''} ${property?.firstCurrentOwnerLastName ?? ''}`,
      phoneNumber:
        property.firstOwnerDncDncStatus === 'DNC'
          ? ''
          : property.firstCurrentOwnerPhoneNumber ?? '',
      phoneNumber2:
        property.firstOwnerDncDncStatus2 === 'DNC'
          ? ''
          : property.firstCurrentOwnerPhoneNumber2 ?? '',
      dncStatus: property.firstOwnerDncDncStatus ?? undefined,
      dncStatus2: property.firstOwnerDncDncStatus2 ?? undefined,
      email: property.firstCurrentOwnerEmail ?? '',
    };
  }

  return {
    fullName: manualProperty?.firstCurrentOwnerFullName ?? '',
    phoneNumber: manualProperty?.firstCurrentOwnerPhoneNumber ?? '',
    phoneNumber2: manualProperty?.firstCurrentOwnerPhoneNumber2 ?? '',
    email: manualProperty?.firstCurrentOwnerEmail ?? '',
  };
};

const getSecondOwner = (
  manualProperty?: ManualProperty,
  property?: Property
): Owner => {
  if (property && !manualProperty?.secondCurrentOwnerFullName) {
    return {
      fullName: `${property.secondCurrentOwnerFirstName ?? ''} ${property?.secondCurrentOwnerLastName ?? ''}`,
      phoneNumber:
        property.secondOwnerDncDncStatus === 'DNC'
          ? ''
          : property.secondCurrentOwnerPhoneNumber ?? '',
      phoneNumber2:
        property.secondOwnerDncDncStatus2 === 'DNC'
          ? ''
          : property.secondCurrentOwnerPhoneNumber2 ?? '',
      dncStatus: property.secondOwnerDncDncStatus ?? undefined,
      dncStatus2: property.secondOwnerDncDncStatus2 ?? undefined,
      email: property.secondCurrentOwnerEmail ?? '',
    };
  }

  return {
    fullName: manualProperty?.secondCurrentOwnerFullName ?? '',
    phoneNumber: manualProperty?.secondCurrentOwnerPhoneNumber ?? '',
    phoneNumber2: manualProperty?.secondCurrentOwnerPhoneNumber2 ?? '',
    email: manualProperty?.secondCurrentOwnerEmail ?? '',
  };
};

export const Owners = ({
  property,
  salesPipeline,
  updateSalesPipelineOwner,
}: OwnersProps) => {
  const commonProperty =
    property ??
    salesPipeline?.property ??
    salesPipeline?.manualProperty ??
    undefined;
  const loading = commonProperty === undefined;

  const hasFirstOwner =
    loading ||
    isFirstOwnerAvailable(property) ||
    salesPipeline?.manualProperty?.firstCurrentOwnerFullName;

  const hasSecondOwner =
    loading ||
    isSecondOwnerAvailable(property) ||
    salesPipeline?.manualProperty?.secondCurrentOwnerFullName;

  return (
    <div className="w-[320px]">
      {hasFirstOwner && (
        <OwnerDetails
          title="FIRST OWNER"
          salesPipeline={salesPipeline}
          owner={getFirstOwner(
            salesPipeline?.manualProperty,
            property ?? salesPipeline?.property
          )}
          updateSalesPipelineOwner={updateSalesPipelineOwner}
          loading={loading}
          position={1}
        />
      )}
      {hasSecondOwner && (
        <OwnerDetails
          title="SECOND OWNER"
          salesPipeline={salesPipeline}
          owner={getSecondOwner(
            salesPipeline?.manualProperty,
            property ?? salesPipeline?.property
          )}
          updateSalesPipelineOwner={updateSalesPipelineOwner}
          loading={loading}
          position={2}
        />
      )}
    </div>
  );
};
