import {
  MapProperty,
  Property,
} from '@/pages/admin/property-data/property-table';
import { Button } from '@/components/ui/button';
import { StageDetails } from '@/components/map/property-details/stage-details';
import {
  SalesPipeline,
  StageEnum,
  UpdateSalesPipeline,
} from '@/pages/crm/lead-stages/types';
import { TickButtonIcon } from '@/components/map/property-details/icons/tick-button-icon';
import { useCallback, useState } from 'react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { DropdownButtonIcon } from '@/components/map/property-details/icons/dropdown-button-icon';
import { AllLostReasons } from '@/components/crm/types';
import { ArrowLeftIcon, ArrowRightIcon } from 'lucide-react';
import { ButtonGroup } from '@/components/map/property-details/button-group';
import { allStages } from '@/components/crm/board/utils';

interface TopSectionProps {
  mapProperty?: MapProperty;
  property?: Property;
  salesPipeline?: SalesPipeline;

  onOpenChange?(open: boolean): void;

  changeStage?(
    salesPipelineId: number,
    fromStage: string,
    toStage: string
  ): Promise<void>;

  closeAsLost?(
    salesPipelineId: number,
    fromStage: string,
    lostReasonCode: number,
    lostReasonDescription: string
  ): Promise<void>;

  reactivate?(salesPipelineId: number): Promise<void>;

  updateSalesPipeline?(
    salesPipelineId: number,
    data: UpdateSalesPipeline
  ): Promise<void>;
}

export const getNextStage = (currentStage: StageEnum): StageEnum | null => {
  const currentIndex = allStages.indexOf(currentStage);
  if (currentIndex === -1 || currentIndex === allStages.length - 1) {
    return null;
  }
  return allStages[currentIndex + 1];
};

export const getPreviousStage = (currentStage: StageEnum): StageEnum | null => {
  const currentIndex = allStages.indexOf(currentStage);
  if (currentIndex <= 0) {
    return null; // No previous stage available
  }
  return allStages[currentIndex - 1];
};

export const TopSection = ({
  salesPipeline,
  changeStage,
  closeAsLost,
  reactivate,
  updateSalesPipeline,
  onOpenChange,
}: TopSectionProps) => {
  const [loading, setLoading] = useState(false);

  const handleUpdateStage = useCallback(
    async (type: 'promote' | 'demote') => {
      if (!salesPipeline) {
        return;
      }

      let toStage = getNextStage(salesPipeline.stage);
      if (type === 'demote') {
        toStage = getPreviousStage(salesPipeline.stage);
      }

      setLoading(true);
      await changeStage?.(
        salesPipeline.id,
        salesPipeline.stage,
        toStage as StageEnum
      );
      setLoading(false);
    },
    [salesPipeline, changeStage]
  );

  const handleCloseAsLost = useCallback(
    async (lostReasonCode: number, lostReasonDescription: string) => {
      if (!salesPipeline) {
        return;
      }

      setLoading(true);
      await closeAsLost?.(
        salesPipeline.id,
        salesPipeline.stage,
        lostReasonCode,
        lostReasonDescription
      );
      setLoading(false);
    },
    [closeAsLost, salesPipeline]
  );

  const handleUpdateReceptiveness = useCallback(
    async (receptiveness: number) => {
      if (!salesPipeline) {
        return;
      }

      setLoading(true);
      await updateSalesPipeline?.(salesPipeline.id, { receptiveness });
      setLoading(false);
    },
    [salesPipeline, updateSalesPipeline]
  );

  const handleReactivate = useCallback(async () => {
    if (!salesPipeline) {
      return;
    }

    setLoading(true);
    await reactivate?.(salesPipeline.id);
    setLoading(false);
  }, [reactivate, salesPipeline]);

  return (
    <div className="flex space-x-8 w-full">
      <div className="w-[320px]">
        <img
          src="/assets/images/property-placeholder.png"
          alt="Property"
          className="w-[320px]"
        />
      </div>
      {salesPipeline && (
        <div className="space-y-12 flex-1">
          <div className="flex justify-between space-x-20">
            <div className="flex items-center space-x-3">
              {salesPipeline.stage !== 'lost' && (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button disabled={loading} variant="blue">
                      <span>
                        Receptiveness
                        {salesPipeline.receptiveness
                          ? `: ${salesPipeline.receptiveness}/5`
                          : ''}
                      </span>
                      <span>
                        <DropdownButtonIcon />
                      </span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="min-w-48 bg-blue-tint text-blue-shade">
                    <DropdownMenuGroup>
                      {[
                        'Not at all receptive',
                        'Slightly receptive',
                        'Moderately receptive',
                        'Very receptive',
                        'Extremely receptive',
                      ].map((description, index) => (
                        <DropdownMenuCheckboxItem
                          key={index}
                          checked={salesPipeline.receptiveness === index + 1}
                          className="focus:text-blue-tint focus:bg-blue-blue"
                          onClick={() => handleUpdateReceptiveness(index + 1)}
                        >
                          {index + 1} - {description}
                        </DropdownMenuCheckboxItem>
                      ))}
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}

              {salesPipeline.stage === 'lost' && (
                <Button
                  onClick={async () => {
                    await handleReactivate();
                  }}
                  disabled={loading}
                  className="uppercase text-label-md text-green-shade bg-green-tint hover:text-green-tint hover:bg-green-green active:text-green-tint active:bg-green-shade flex items-center space-x-1.5"
                >
                  <span>
                    <TickButtonIcon />
                  </span>
                  <span>Reactivate</span>
                </Button>
              )}

              {[
                'assigned_lead',
                'follow_up',
                'front_line_processing',
                'refinancing',
              ].includes(salesPipeline.stage) && (
                <ButtonGroup>
                  <Button
                    onClick={async () => {
                      await handleUpdateStage('demote');
                    }}
                    disabled={
                      loading || salesPipeline.stage === 'assigned_lead'
                    }
                    variant="yellow"
                  >
                    <span>
                      <ArrowLeftIcon />
                    </span>
                    <span>Demote</span>
                  </Button>

                  <Button
                    onClick={async () => {
                      await handleUpdateStage('promote');
                    }}
                    disabled={loading}
                    variant="green"
                  >
                    <span>Promote</span>
                    <span>
                      <ArrowRightIcon />
                    </span>
                  </Button>
                </ButtonGroup>
              )}

              {[
                'assigned_lead',
                'follow_up',
                'front_line_processing',
                'refinancing',
              ].includes(salesPipeline.stage) && (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button disabled={loading} variant="red">
                      <span>
                        <TickButtonIcon />
                      </span>
                      <span>Close as Lost</span>
                      <span>
                        <DropdownButtonIcon />
                      </span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="min-w-64 bg-red-tint text-red-shade">
                    <DropdownMenuGroup>
                      {AllLostReasons.map((description, index) => (
                        <DropdownMenuItem
                          key={index}
                          className="focus:text-red-tint focus:bg-red-red"
                          onClick={() =>
                            handleCloseAsLost(index + 1, description)
                          }
                        >
                          {index + 1} - {description}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </div>
            <div>
              <Button onClick={() => onOpenChange?.(false)}>Close</Button>
            </div>
          </div>

          <div>
            <StageDetails salesPipeline={salesPipeline} />
          </div>
        </div>
      )}

      {!salesPipeline && (
        <div className="space-y-12 flex-1">
          <div className="w-full flex justify-between space-x-20">
            <div></div>
            <div>
              <Button onClick={() => onOpenChange?.(false)}>Close</Button>
            </div>
          </div>

          <div>
            <StageDetails salesPipeline={salesPipeline} />
          </div>
        </div>
      )}
    </div>
  );
};
