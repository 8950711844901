import { Property } from '@/pages/admin/property-data/property-table';

const _isNameAvailable = (
  firstName: string | null,
  lastName: string | null
): boolean => {
  function hasThreeOrMoreWords(name?: string | null): boolean {
    if (!name) {
      return false;
    }
    return name.split(' ').length >= 3;
  }

  function isSingleWord(name: string): boolean {
    return name.split(' ').length === 1;
  }

  const restrictedWords = [
    'null',
    'inc',
    'l.l.c.',
    'incorporated',
    'city',
    'state',
    'university',
    'college',
    'trust',
    'llc',
    'revocable',
    'capital',
    'living',
    'county',
  ];

  if (firstName || lastName) {
    let fullName = `${firstName ?? ''} ${lastName ?? ''}`.trim().toLowerCase();

    if (restrictedWords.some((word) => fullName.includes(word))) {
      // console.log(`Owner name contains restricted word: '${fullName}'`);
      return false;
    }

    if (hasThreeOrMoreWords(firstName) || hasThreeOrMoreWords(lastName)) {
      // console.log(`Owner name is too long: '${fullName}'`);
      return false;
    }

    fullName = `${firstName || ''} ${lastName || ''}`.trim();
    if (isSingleWord(fullName)) {
      // console.log(`Owner name is too short: '${fullName}'`);
      return false;
    }

    return true;
  }

  return false;
};

export const isFirstOwnerAvailable = (property?: Property): boolean => {
  if (!property) {
    return false;
  }

  return _isNameAvailable(
    property.firstCurrentOwnerFirstName,
    property.firstCurrentOwnerLastName
  );
};

export const isSecondOwnerAvailable = (property?: Property): boolean => {
  if (!property) {
    return false;
  }

  return _isNameAvailable(
    property.secondCurrentOwnerFirstName,
    property.secondCurrentOwnerLastName
  );
};
