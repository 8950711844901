import { useEffect, useState } from 'react';
import { BoardTitle } from '@/components/crm/board/board-title';
import { SalesPipeline, StageEnum } from '@/pages/crm/lead-stages/types';
import { DraggableBoardCard } from '@/components/crm/board/draggable-board-card';
import { useDrop } from 'react-dnd';
import {
  allStages,
  BoardSortState,
  canCardMove,
  SortOrder,
  sortSalesPipelines,
} from '@/components/crm/board/utils';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { DropdownButtonIcon } from '@/components/map/property-details/icons/dropdown-button-icon';

interface BoardColumnProps {
  twoColumns?: boolean;
  stage: StageEnum;
  salesPipelines: SalesPipeline[];
  isSuperuser?: boolean;
  onShowDetails?(salesPipeline: SalesPipeline): void;
  moveCard?(salesPipeline: SalesPipeline, stage: StageEnum): Promise<void>;
  boardSortState: BoardSortState;
  updateBoardSortState(stage: StageEnum, sortOrder: SortOrder): void;
  openEmailDialog(salesPipeline: SalesPipeline): void;
}

export const MORTGAGE_TYPE_MAP: Record<string, string> = {
  'Federal Housing Administration': 'FHA',
  'Veterans Affairs': 'VA',
  Conventional: 'CNV',
};

const Overlay = ({ color }: { color: string }) => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      zIndex: 1,
      opacity: 0.5,
      backgroundColor: color,
    }}
  />
);

export const BoardColumn = ({
  twoColumns,
  stage,
  salesPipelines,
  onShowDetails,
  moveCard,
  isSuperuser,
  boardSortState,
  updateBoardSortState,
  openEmailDialog,
}: BoardColumnProps) => {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: allStages.filter((s) => s !== stage),
      drop: async (item) => await moveCard?.(item, stage),
      canDrop: (item: SalesPipeline) => canCardMove(item.stage, stage),
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [stage]
  );

  const sortOrder = boardSortState[stage] ?? 'oldest';
  const [sortedPipelines, setSortedPipelines] = useState<SalesPipeline[]>([]);

  useEffect(() => {
    const sorted = sortSalesPipelines(salesPipelines, sortOrder);
    setSortedPipelines(sorted);
  }, [salesPipelines, sortOrder]);

  return (
    <div
      className={`2xl:w-auto ${twoColumns ? 'max-w-[760px] min-w-[640px]' : 'max-w-[380px] min-w-[350px]'}`}
    >
      <BoardTitle stage={stage} cardCount={salesPipelines.length} />
      <div className="bg-[#e5e7ee] p-1.5 flex items-center justify-between px-1.5">
        <div className="text-label-md text-navy-navy">Sort By Activity</div>
        <div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="blue">
                <span>{sortOrder.toUpperCase()}</span>
                <span>
                  <DropdownButtonIcon />
                </span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="min-w-48 bg-blue-tint text-blue-shade">
              <DropdownMenuGroup>
                <DropdownMenuCheckboxItem
                  className="focus:text-blue-tint focus:bg-blue-blue"
                  checked={sortOrder === 'newest'}
                  onClick={() => updateBoardSortState(stage, 'newest')}
                >
                  NEWEST
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  className="focus:text-blue-tint focus:bg-blue-blue"
                  checked={sortOrder === 'oldest'}
                  onClick={() => updateBoardSortState(stage, 'oldest')}
                >
                  OLDEST
                </DropdownMenuCheckboxItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div
        className="h-full bg-gradient-to-b from-[#e5e7ee] to-[#e5e7ee] rounded-bl-lg rounded-br-lg w-full pb-3.5"
        ref={drop}
        style={{
          position: 'relative',
          width: '100%',
          height: 'calc(100vh - 258px)',
        }}
      >
        <div
          className={`space-y-3.5 max-h-full px-1.5 overflow-y-auto ${twoColumns ? '2xl:grid 2xl:grid-cols-2 2xl:gap-3.5 2xl:space-y-0' : ''}`}
        >
          {sortedPipelines.map((salesPipeline) => (
            <DraggableBoardCard
              key={salesPipeline.id}
              onShowDetails={() => onShowDetails?.(salesPipeline)}
              salesPipeline={salesPipeline}
              isSuperuser={isSuperuser}
              openEmailDialog={openEmailDialog}
            />
          ))}
        </div>
        {isOver &&
          (canDrop ? <Overlay color="yellow" /> : <Overlay color="red" />)}
      </div>
    </div>
  );
};
