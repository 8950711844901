import { FilterContainer } from '@/components/filters/sections/filter-container';
import { DateRange } from 'react-day-picker';
import { DateRangeSelector } from '@/components/filters/sections/date-range-selector';

export interface SaleDateFilterProps {
  selectedSaleDateRange: DateRange;
  onSelectSaleDateRange: (selectedSaleDateRange: DateRange) => void;
}

export const SaleDateFilter = ({
  selectedSaleDateRange,
  onSelectSaleDateRange,
}: SaleDateFilterProps) => {
  return (
    <FilterContainer
      value="state"
      title={
        <div className="text-heading-05 text-dark-dark">Sale Date Range</div>
      }
    >
      <DateRangeSelector
        selectedDateRange={selectedSaleDateRange}
        onSelectDateRange={onSelectSaleDateRange}
      />
    </FilterContainer>
  );
};
