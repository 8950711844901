import { ECharts } from './e-charts';
import { EChartsOption, SeriesOption } from 'echarts';
import { quartileColors } from '@/utils/colors';
import { moneyFormatter } from '@/utils/formaters';
import { formatShortestMoney } from '@/utils';
import { ReactNode } from 'react';

interface MarketOpportunityData {
  firstQuartile: number[];
  secondQuartile: number[];
  thirdQuartile: number[];
  fourthQuartile: number[];
  opportunitiesAvailable: number[];
}

interface MarketQuartileData {
  count: number;
  value: number;
}

interface MarketCountyStatsData {
  state: string;
  quartiles: MarketQuartileData[];
}

interface MarketOpportunityChartProps {
  stats: MarketCountyStatsData[];
  title: string;
  algorithm: string;
  valueTitle: ReactNode;
  width?: number | string;
  height?: number | string;
}

const formatCurrency = (value: number) => moneyFormatter.format(value);

const createSeries = (
  name: string,
  data: number[],
  maxValue: number,
  color: string
): SeriesOption => {
  return {
    name: name,
    type: 'bar',
    stack: 'total',
    label: {
      show: true,
      position: 'inside',
      formatter: (params) => {
        const value = params.value as number;
        const relativeHeight = value / maxValue;
        if (relativeHeight < 0.03) {
          return '';
        } else {
          return formatCurrency(value);
        }
      },
    },
    tooltip: {
      valueFormatter: (value) => formatCurrency(value as number),
    },
    data: data,
    itemStyle: {
      color: color,
    },
  };
};

const getMaxBarValue = (data: MarketOpportunityData): number => {
  const quartiles = [
    data.firstQuartile,
    data.secondQuartile,
    data.thirdQuartile,
    data.fourthQuartile,
  ];
  const totals = quartiles[0].map((_, i) =>
    quartiles.reduce((sum, quart) => sum + quart[i], 0)
  );
  return Math.max(...totals);
};

export const MarketOpportunityChart = ({
  valueTitle,
  title,
  algorithm,
  stats,
  width,
  height,
}: MarketOpportunityChartProps) => {
  const data: MarketOpportunityData = {
    firstQuartile: stats.map((row) => row.quartiles[0].value),
    secondQuartile: stats.map((row) => row.quartiles[1]?.value ?? 0),
    thirdQuartile: stats.map((row) => row.quartiles[2]?.value ?? 0),
    fourthQuartile: stats.map((row) => row.quartiles[3]?.value ?? 0),
    opportunitiesAvailable: stats.map((row) =>
      row.quartiles.reduce((sum, quartile) => sum + quartile.count, 0)
    ),
  };
  const states = stats.map((row) => row.state.toUpperCase());
  const maxBarValue = getMaxBarValue(data);

  const option: EChartsOption = {
    textStyle: {
      fontFamily:
        'Lato, -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    },
    grid: {
      left: 20,
      right: 20,
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999',
        },
      },
    },
    legend: {
      data: [
        '4th Quartile ($$$$)',
        '3rd Quartile ($$$)',
        '2nd Quartile ($$)',
        '1st Quartile ($)',
        'Opportunities Available',
      ],
      right: 0,
    },
    xAxis: [
      {
        type: 'category',
        data: states,
        axisPointer: {
          type: 'shadow',
        },
        axisLabel: {
          color: '#66719A',
          fontSize: 12,
          fontFamily: 'Lato',
          fontWeight: 400,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        alignTicks: true,
        axisLabel: {
          formatter: (value: number) => `$${formatShortestMoney(value)}`,
          color: '#66719A',
          fontSize: 12,
          fontFamily: 'Lato',
          fontWeight: 400,
        },
      },
      {
        type: 'value',
        alignTicks: true,
        axisLabel: {
          formatter: '{value}',
          color: '#66719A',
          fontSize: 12,
          fontFamily: 'Lato',
          fontWeight: 400,
        },
      },
    ],
    series: [
      createSeries(
        '1st Quartile ($)',
        data.firstQuartile,
        maxBarValue,
        quartileColors[1]
      ),
      createSeries(
        '2nd Quartile ($$)',
        data.secondQuartile,
        maxBarValue,
        quartileColors[2]
      ),
      createSeries(
        '3rd Quartile ($$$)',
        data.thirdQuartile,
        maxBarValue,
        quartileColors[3]
      ),
      createSeries(
        '4th Quartile ($$$$)',
        data.fourthQuartile,
        maxBarValue,
        quartileColors[4]
      ),
      {
        name: 'Opportunities Available',
        type: 'line',
        yAxisIndex: 1,
        tooltip: {
          valueFormatter: (value) => `${value as number}`,
        },
        data: data.opportunitiesAvailable,
      },
    ],
  };

  if (Object.keys(stats).length === 0) {
    return null;
  }

  return (
    <div className="space-y-2 bg-light-light rounded-lg p-6">
      <div className="text-heading-01 text-navy-navy">{title}</div>
      <div className="text-heading-04 text-navy-navy">{algorithm}</div>

      <div className="flex">
        <div className="text-heading-04 text-navy-navy rotate-vertical">
          {valueTitle}
        </div>
        <div className="flex-1">
          <ECharts
            option={option}
            style={{ width: width, height: height ?? 500 }}
          />
        </div>
        <div className="text-heading-04 text-navy-navy rotate-vertical">
          Total Opportunities available
        </div>
      </div>
    </div>
  );
};
