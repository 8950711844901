import { FilterContainer } from '@/components/filters/sections/filter-container';
import { CheckboxWithLabel } from '@/components/filters/sections/checkbox-with-label';

interface Option {
  value: boolean;
  label: string;
}

const OPTIONS: Option[] = [
  {
    value: false,
    label: 'Yes',
  },
  {
    value: true,
    label: 'No',
  },
];

export interface DoNotMailFilterProps {
  selectedDoNotMails: boolean[];
  onSelectDoNotMails: (selectedDoNotMails: boolean[]) => void;
}

export const DoNotMailFilter = ({
  selectedDoNotMails,
  onSelectDoNotMails,
}: DoNotMailFilterProps) => {
  const handleDoNotMailsChange = (doNotMails: boolean) => {
    if (selectedDoNotMails.includes(doNotMails)) {
      const newDoNotMails = selectedDoNotMails.filter(
        (item) => item !== doNotMails
      );
      if (newDoNotMails.length > 0) {
        onSelectDoNotMails(newDoNotMails);
      }
    } else {
      onSelectDoNotMails([...selectedDoNotMails, doNotMails]);
    }
  };

  return (
    <FilterContainer
      value="mailable-property"
      title={
        <div className="text-heading-05 text-dark-dark">Mailable Property</div>
      }
    >
      <div className="flex-col items-center justify-center space-y-2 px-6">
        {OPTIONS.map(({ value, label }) => (
          <CheckboxWithLabel
            key={label}
            id={`emailable-owner-${value}`}
            label={label}
            checked={selectedDoNotMails.includes(value)}
            onClick={() => handleDoNotMailsChange(value)}
          />
        ))}
      </div>
    </FilterContainer>
  );
};
