import mapboxgl from 'mapbox-gl';
import {
  MapProperty,
  MapPropertyWithStage,
} from '@/pages/admin/property-data/property-table';
import {
  defaultAprRange,
  defaultBuildYearRange,
  defaultMortgageBalanceRange,
  defaultSaleDateRange,
} from '@/components/filters/sections/constants';
import { OpportunityValue } from '@/components/filters/sections/opportunity-value-filter';
import { MapFilterState } from '@/pages/map/map/types';

export const showMarkerByFilter = (
  property: MapPropertyWithStage,
  filter: MapFilterState
) => {
  const apr = parseFloat(
    property.originalMortgageInterestRate?.toString() ?? '0.0'
  );
  const balance = parseInt(property.originalMortgageAmount?.toString() ?? '0');
  const buildYear = property.yearBuilt ?? 0;
  const saleDate = new Date(
    property.recordDateLastArmsLengthSale ?? '1800-01-01'
  );
  const hasNoAprFilter =
    filter.selectedAprRange.from === defaultAprRange.from &&
    filter.selectedAprRange.to === defaultAprRange.to;
  const hasNoMortgageBalanceFilter =
    filter.selectedMortgageBalanceRange.from ===
      defaultMortgageBalanceRange.from &&
    filter.selectedMortgageBalanceRange.to === defaultMortgageBalanceRange.to;
  const hasNoBuildYearFilter =
    filter.selectedBuildYearRange.from === defaultBuildYearRange.from &&
    filter.selectedBuildYearRange.to === defaultBuildYearRange.to;
  const hasNoSaleDateFilter =
    filter.selectedSaleDateRange.from === defaultSaleDateRange.from &&
    filter.selectedSaleDateRange.to === defaultSaleDateRange.to;
  return (
    filter.selectedStages.includes(property.stage) &&
    filter.selectedStates.includes(property.state) &&
    filter.selectedOpportunityValues.includes(
      property.quartile as OpportunityValue
    ) &&
    filter.selectedLeaderCompetitiveness.includes(
      property.lenderCompetitivenessRanking
    ) &&
    filter.selectedCallableOwners.includes(property.callableOwners) &&
    filter.selectedEmailableOwners.includes(property.emailableOwners) &&
    filter.selectedMortgageTypes.includes(property.originalMortgageType) &&
    filter.selectedDoNotMail.includes(property.doNotMail) &&
    (hasNoAprFilter ||
      (apr >= filter.selectedAprRange.from &&
        apr <= filter.selectedAprRange.to)) &&
    (hasNoMortgageBalanceFilter ||
      (balance >= filter.selectedMortgageBalanceRange.from &&
        balance <= filter.selectedMortgageBalanceRange.to)) &&
    (hasNoBuildYearFilter ||
      (buildYear >= filter.selectedBuildYearRange.from &&
        buildYear <= filter.selectedBuildYearRange.to)) &&
    (hasNoSaleDateFilter ||
      (saleDate >= filter.selectedSaleDateRange.from! &&
        saleDate <= filter.selectedSaleDateRange.to!)) &&
    (property.assignedUserId === undefined ||
      filter.selectedUserId === undefined ||
      property.assignedUserId === filter.selectedUserId)
  );
};

interface LoadMapPropertiesParams {
  map: mapboxgl.Map;
  onPropertyClick(property: MapProperty): void;
}

export const loadMapProperties = ({
  map,
  onPropertyClick,
}: LoadMapPropertiesParams) => {
  if (map.getSource('properties')) {
    return;
  }

  const geojsonData = {
    type: 'FeatureCollection',
    features: [],
  };

  map.addSource('properties', {
    type: 'geojson',
    data: geojsonData as GeoJSON.FeatureCollection<GeoJSON.Geometry>,
    cluster: true,
    clusterMaxZoom: 8,
    clusterRadius: 100,
  });

  map.addLayer({
    id: 'properties-custom-icons-layer',
    type: 'symbol',
    source: 'properties',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-allow-overlap': true,
      'icon-image': [
        'match',
        ['get', 'quartile'],
        1,
        'custom-marker-quartile-1',
        2,
        'custom-marker-quartile-2',
        3,
        'custom-marker-quartile-3',
        4,
        'custom-marker-quartile-4',
        'custom-marker-quartile-4', // Fallback image
      ],
      'icon-size': ['interpolate', ['linear'], ['zoom'], 12, 0.3, 14, 0.7],
    },
  });

  map.addLayer({
    id: 'clusters',
    type: 'symbol',
    source: 'properties',
    filter: ['has', 'point_count'],
    layout: {
      'icon-allow-overlap': true,
      'icon-image': 'cluster-icon',
      'icon-size': [
        'step',
        ['get', 'point_count'],
        0.2, // Default size for 0 points (should not happen in clusters)
        10,
        0.3, // Size 0.6 for clusters with 10 or fewer points
        100,
        0.4, // Size 0.8 for clusters with 100 or fewer points
        500,
        0.5, // Size 1.0 for clusters with 500 or fewer points
        1000,
        0.6, // Size 1.2 for clusters with more than 1000 points
      ],
    },
  });

  map.addLayer({
    id: 'cluster-count',
    type: 'symbol',
    source: 'properties',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': ['get', 'point_count_abbreviated'],
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      // 'text-size': 12,
      'text-size': [
        'interpolate',
        ['linear'],
        ['get', 'point_count'],
        1,
        9, // Default size for 0 points (should not happen in clusters)
        10,
        9, // 10 or fewer points
        100,
        11, // 100 or fewer points
        500,
        13, // 500 or fewer points
        1000,
        13, // More than 1000 points
      ],
      'text-offset': [
        'step',
        ['get', 'point_count'],
        [0, -0.4], // Default size for 0 points (should not happen in clusters)
        10,
        [0, -0.7], // 10 or fewer points
        100,
        [0, -0.7], // 100 or fewer points
        500,
        [0, -0.9], // 500 or fewer points
        1000,
        [0, -1.1], // More than 1000 points
      ],
    },
    paint: {
      'text-color': 'white',
    },
  });

  const canvas = map.getCanvas();
  if (canvas) {
    map.on('mouseenter', 'properties-custom-icons-layer', () => {
      canvas.style.cursor = 'pointer';
    });

    map.on('mouseleave', 'properties-custom-icons-layer', () => {
      canvas.style.cursor = '';
    });

    map.on('mouseenter', 'clusters', () => {
      canvas.style.cursor = 'pointer';
    });

    map.on('mouseleave', 'clusters', () => {
      canvas.style.cursor = '';
    });
  }

  map.on('click', 'clusters', (e) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: ['clusters'],
    });
    const clusterId = features?.[0]?.properties?.cluster_id;

    if (features && features[0].geometry.type === 'Point') {
      const pointGeometry = features[0].geometry as GeoJSON.Point;
      const coordinates: [number, number] = [
        pointGeometry.coordinates[0],
        pointGeometry.coordinates[1],
      ];

      (
        map.getSource('properties') as mapboxgl.GeoJSONSource
      ).getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;

        map.easeTo({
          center: coordinates,
          zoom: zoom,
        });
      });
    }
  });

  // Implement click event for the GeoJSON layer
  map.on('click', 'properties-custom-icons-layer', (e) => {
    const property = e.features?.[0]?.properties as MapProperty | undefined;
    if (!property) {
      return;
    }

    onPropertyClick(property);
  });
};
