import { MiniStats } from '@/components';
import { ContactIcon, CupIcon, ShakeHandIcon } from '@/components/icons';
import { DollarIcon } from '@/pages/crm/team/team/dollar-icon';
import { LeaderboardStatItem } from '@/pages/crm/team/team/types';
import { formatShortestMoney } from '@/utils';
import { calculateConversionRate } from '@/pages/crm/team/team/utils';

interface LeaderboardItemProps {
  order: number;
  data: LeaderboardStatItem;
}

export const LeaderboardItem = ({ order, data }: LeaderboardItemProps) => {
  const monthlyConversionRate = calculateConversionRate(
    data.month.refinanced,
    data.month.followUp
  );

  const quarterlyConversionRate = calculateConversionRate(
    data.quarter.refinanced,
    data.quarter.followUp
  );

  const yearlyConversionRate = calculateConversionRate(
    data.year.refinanced,
    data.year.followUp
  );

  return (
    <div className="w-full px-6 py-2 bg-light-tint rounded-lg shadow">
      <div className="flex items-center space-x-3">
        <div className="text-display-01 text-navy-navy w-16">{order}</div>
        <div>
          <img
            src="/assets/images/avatar.png"
            alt="Avatar Icon"
            className="w-12 h-12"
          />
        </div>
        <div className="text-heading-04 text-navy-navy">{data.userName}</div>
      </div>
      <div className="bg-light-light p-3 grid grid-cols-2 2xl:grid-cols-4 gap-4">
        <div className="space-y-3">
          <div className="text-heading-04 text-navy-navy">Follow-up</div>
          <div className="flex space-x-3 2xl:space-x-1 3xl:space-x-3">
            <MiniStats
              variant="orange-tint"
              icon={<ContactIcon className="h-6 w-6 text-orange-tint" />}
              value={data.month.followUp}
              label="Monthly"
              small
            />
            <MiniStats
              variant="orange"
              icon={<ContactIcon className="h-6 w-6 text-orange-orange" />}
              value={data.quarter.followUp}
              label="Quarterly"
              small
            />
            <MiniStats
              variant="orange-shade"
              icon={<ContactIcon className="h-6 w-6 text-orange-shade" />}
              value={data.year.followUp}
              label="Yearly"
              small
            />
          </div>
        </div>
        <div className="space-y-3">
          <div className="text-heading-04 text-navy-navy">Refinanced Leads</div>
          <div className="flex space-x-3 2xl:space-x-1 3xl:space-x-3">
            <MiniStats
              variant="blue-tint"
              icon={<CupIcon className="h-6 w-6 text-blue-tint" />}
              value={data.month.refinanced.toLocaleString()}
              label="Monthly"
              small
            />
            <MiniStats
              variant="blue"
              icon={<CupIcon className="h-6 w-6 text-blue-blue" />}
              value={data.quarter.refinanced.toLocaleString()}
              label="Quarterly"
              small
            />
            <MiniStats
              variant="blue-shade"
              icon={<CupIcon className="h-6 w-6 text-blue-shade" />}
              value={data.year.refinanced.toLocaleString()}
              label="Yearly"
              small
            />
          </div>
        </div>
        <div className="space-y-3">
          <div className="text-heading-04 text-navy-navy">Conversion Rate</div>
          <div className="flex space-x-3 2xl:space-x-1 3xl:space-x-3">
            <MiniStats
              variant="navy-tint"
              icon={<ShakeHandIcon className="h-6 w-6 text-navy-tint" />}
              value={monthlyConversionRate}
              label="Monthly"
              small
            />
            <MiniStats
              variant="navy"
              icon={<ShakeHandIcon className="h-6 w-6 text-navy-navy" />}
              value={quarterlyConversionRate}
              label="Quarterly"
              small
            />
            <MiniStats
              variant="navy-shade"
              icon={<ShakeHandIcon className="h-6 w-6 text-navy-shade" />}
              value={yearlyConversionRate}
              label="Yearly"
              small
            />
          </div>
        </div>
        <div className="space-y-3">
          <div className="text-heading-04 text-navy-navy">
            Refinanced Mtg. Bals. Sum
          </div>
          <div className="flex space-x-3 2xl:space-x-1 3xl:space-x-3">
            <MiniStats
              variant="green-tint"
              icon={<DollarIcon className="h-6 w-6 text-green-tint" />}
              value={formatShortestMoney(data.month.refinancedAmount)}
              label="Monthly"
              small
            />
            <MiniStats
              variant="green"
              icon={<DollarIcon className="h-6 w-6 text-green-green" />}
              value={formatShortestMoney(data.quarter.refinancedAmount)}
              label="Quarterly"
              small
            />
            <MiniStats
              variant="green-shade"
              icon={<DollarIcon className="h-6 w-6 text-green-shade" />}
              value={formatShortestMoney(data.year.refinancedAmount)}
              label="Yearly"
              small
            />
          </div>
        </div>
      </div>
    </div>
  );
};
