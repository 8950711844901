import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Input } from '@/components/ui/input';
import { MarketFilter } from '@/components/market/assign-leads-dialog/type';
import { MORTGAGE_TYPE_MAP } from '@/components/crm/board/board-column';
import { formatShortestMoney, stateMap } from '@/utils';
import { useEffect, useState } from 'react';
import { User } from '@/pages';

interface AssignLeadsDialogProps {
  filter: MarketFilter;
  open: boolean;
  onClose?(): void;
  numberOfAllowedAssignedLeads?: number;
  assignLeads?(numberOfLeads: number, userId?: number): void;
  assigning?: boolean;
  users?: User[];
  isAdmin?: boolean;
}

interface FormState {
  count: number;
  userId?: number;
}

export const AssignLeadsDialog = ({
  filter,
  open,
  onClose,
  numberOfAllowedAssignedLeads,
  assignLeads,
  assigning,
  users,
  isAdmin,
}: AssignLeadsDialogProps) => {
  const [state, setState] = useState<FormState>({ count: 100 });

  useEffect(() => {
    if (isAdmin) {
      setState({
        count: 100,
        userId: undefined,
      });
    } else if (numberOfAllowedAssignedLeads) {
      setState({
        count:
          numberOfAllowedAssignedLeads > 100
            ? 100
            : numberOfAllowedAssignedLeads,
        userId: undefined,
      });
    }
  }, [isAdmin, numberOfAllowedAssignedLeads]);

  return (
    <Dialog open={open} onOpenChange={() => onClose?.()}>
      <DialogContent className="sm:max-w-[900px]">
        <DialogHeader>
          <DialogTitle>Assign Leads</DialogTitle>
        </DialogHeader>
        <div className="flex items-start justify-between 2 gap-4 py-4">
          <div className="space-y-3">
            <div className="text-label-lg">Market Filters Applied</div>
            <div className="flex flex-col space-y-3 max-w-[600px]">
              <div className="space-x-3">
                <Badge variant="blue">
                  Est. Mortgage Balance: $
                  {formatShortestMoney(
                    filter.selectedMortgageBalanceRange.from
                  )}{' '}
                  - $
                  {formatShortestMoney(filter.selectedMortgageBalanceRange.to)}
                </Badge>
                <Badge variant="blue">
                  IR: {filter.selectedAprRange.from}% -{' '}
                  {filter.selectedAprRange.to}%
                </Badge>
              </div>
              <div className="space-x-3">
                <Badge variant="blue">
                  Build Yr: {filter.selectedBuildYearRange.from} -{' '}
                  {filter.selectedBuildYearRange.to}
                </Badge>
                <Badge variant="blue">
                  Mortgage Type:{' '}
                  {filter.selectedMortgageTypes
                    .map((type) => MORTGAGE_TYPE_MAP[type])
                    .join(', ')}
                </Badge>
              </div>
              <div>
                <Badge variant="blue">
                  {filter.selectedStates
                    .map((state) => stateMap[state] ?? state)
                    .join(', ')}
                </Badge>
              </div>
            </div>
          </div>
          <div className="space-y-6">
            <div className="flex flex-col space-y-3 items-end">
              <div className="text-label-md">Adjust Lead Volume</div>
              <div>
                <Input
                  type="number"
                  className="w-24"
                  value={state.count}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      count: parseInt(e.target.value),
                    }))
                  }
                />{' '}
              </div>
              {numberOfAllowedAssignedLeads !== undefined && !isAdmin && (
                <div className="text-paragraph-md text-red-red">
                  Maximum: {numberOfAllowedAssignedLeads}
                </div>
              )}
            </div>

            {isAdmin && (
              <div className="flex flex-col space-y-3 items-end">
                <div className="text-label-md">Assign to</div>
                <div>
                  <Select
                    onValueChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        userId: parseInt(value),
                      }))
                    }
                    value={state.userId?.toString()}
                  >
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Select a user" />
                    </SelectTrigger>
                    <SelectContent>
                      {users?.map((user) => (
                        <SelectItem value={user.id.toString()} key={user.id}>
                          {user.firstName} {user.lastName}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}
          </div>
        </div>
        <DialogFooter>
          <Button
            type="submit"
            disabled={
              numberOfAllowedAssignedLeads === undefined ||
              numberOfAllowedAssignedLeads === 0 ||
              state.count > numberOfAllowedAssignedLeads ||
              assigning ||
              (isAdmin && users === undefined) ||
              (isAdmin && state.userId === undefined)
            }
            onClick={() => assignLeads?.(state.count, state.userId)}
          >
            {assigning ? 'Assigning' : 'Assign Now'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
