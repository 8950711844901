import { z } from 'zod';
import { SalesPipeline, StageEnum } from '@/pages/crm/lead-stages/types';
import {
  CommonProperty,
  MortgageType,
} from '@/pages/admin/property-data/property-table';
import { MORTGAGE_TYPE_MAP } from '@/components/crm/board/board-column';

const SortOrderSchema = z.enum(['oldest', 'newest']);
export type SortOrder = z.infer<typeof SortOrderSchema>;

const BoardSortStateSchema = z.object({
  assigned_lead: SortOrderSchema,
  follow_up: SortOrderSchema,
  front_line_processing: SortOrderSchema,
  refinancing: SortOrderSchema,
  refinanced: SortOrderSchema,
  lost: SortOrderSchema,
});

export type BoardSortState = z.infer<typeof BoardSortStateSchema>;

const defaultBordSortState: BoardSortState = {
  assigned_lead: 'oldest',
  follow_up: 'oldest',
  front_line_processing: 'oldest',
  refinancing: 'oldest',
  refinanced: 'oldest',
  lost: 'oldest',
};

export const allStages: StageEnum[] = [
  'assigned_lead',
  'follow_up',
  'front_line_processing',
  'refinancing',
  'refinanced',
  'lost',
];

const moveableStages: Record<StageEnum, StageEnum[]> = {
  assigned_lead: ['follow_up', 'lost'],
  follow_up: ['assigned_lead', 'front_line_processing', 'lost'],
  front_line_processing: ['follow_up', 'refinancing', 'lost'],
  refinancing: ['front_line_processing', 'refinanced', 'lost'],
  refinanced: ['refinancing'],
  lost: [
    'assigned_lead',
    'follow_up',
    'front_line_processing',
    'refinancing',
    'refinanced',
  ],
};

export const canCardMove = (source: StageEnum, destination: StageEnum) => {
  return moveableStages[source].includes(destination);
};

export const isPromoteStage = (source: StageEnum, destination: StageEnum) => {
  return (
    (source === 'assigned_lead' && destination === 'follow_up') ||
    (source === 'follow_up' && destination === 'front_line_processing') ||
    (source === 'front_line_processing' && destination === 'refinancing') ||
    (source === 'refinancing' && destination === 'refinanced')
  );
};

export const isDemoteStage = (source: StageEnum, destination: StageEnum) => {
  return (
    (source === 'follow_up' && destination === 'assigned_lead') ||
    (source === 'front_line_processing' && destination === 'follow_up') ||
    (source === 'refinancing' && destination === 'front_line_processing') ||
    (source === 'refinanced' && destination === 'refinancing')
  );
};

export const isReactivate = (source: StageEnum, destination: StageEnum) => {
  return (
    source === 'lost' &&
    [
      'assigned_lead',
      'follow_up',
      'front_line_processing',
      'refinancing',
      'refinanced',
    ].includes(destination)
  );
};

export const isClose = (source: StageEnum, destination: StageEnum) => {
  if (destination !== 'lost') {
    return false;
  }

  return [
    'assigned_lead',
    'follow_up',
    'front_line_processing',
    'refinancing',
  ].includes(source);
};

export const getMortgageType = (
  salesPipeline?: SalesPipeline
): MortgageType | undefined => {
  const commonProperty =
    salesPipeline?.property ??
    (salesPipeline?.manualProperty as CommonProperty);
  return (MORTGAGE_TYPE_MAP[commonProperty?.originalMortgageType ?? ''] ??
    commonProperty?.originalMortgageType) as MortgageType;
};

export const getOwnerEmails = (
  salesPipeline?: SalesPipeline
): (string | null | undefined)[] => {
  if (!salesPipeline) return [];

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateEmail = (email: string | null | undefined): boolean => {
    return email ? emailRegex.test(email) : false;
  };

  const emails = new Set<string>();

  if (salesPipeline.manualProperty) {
    const firstOwnerEmail = salesPipeline.manualProperty.firstCurrentOwnerEmail;
    const secondOwnerEmail =
      salesPipeline.manualProperty.secondCurrentOwnerEmail;
    if (validateEmail(firstOwnerEmail)) emails.add(firstOwnerEmail as string);
    if (validateEmail(secondOwnerEmail)) emails.add(secondOwnerEmail as string);
  }

  if (salesPipeline.property) {
    const firstOwnerEmail = salesPipeline.property.firstCurrentOwnerEmail;
    const secondOwnerEmail = salesPipeline.property.secondCurrentOwnerEmail;
    if (validateEmail(firstOwnerEmail)) emails.add(firstOwnerEmail as string);
    if (validateEmail(secondOwnerEmail)) emails.add(secondOwnerEmail as string);
  }

  return Array.from(emails);
};

export const getOwnerEmail = (
  salesPipeline?: SalesPipeline
): string | null | undefined => {
  const emails = getOwnerEmails(salesPipeline);
  return emails.length > 0 ? emails[0] : null;
};

export const fetchBoardSortState = (): BoardSortState => {
  const savedState = localStorage.getItem('boardSortState');
  if (savedState) {
    try {
      return BoardSortStateSchema.parse(JSON.parse(savedState));
    } catch (e) {
      console.error('Invalid board sort state in localStorage:', e);
    }
  }
  return defaultBordSortState;
};

export const storeBoardSortState = (state: BoardSortState) => {
  localStorage.setItem('boardSortState', JSON.stringify(state));
};

export const sortSalesPipelines = (
  salesPipelines: SalesPipeline[],
  sortOrder: SortOrder
): SalesPipeline[] => {
  return [...salesPipelines].sort((a, b) => {
    const aLastActivity = a.lastActivityAt
      ? new Date(a.lastActivityAt).getTime()
      : null;
    const bLastActivity = b.lastActivityAt
      ? new Date(b.lastActivityAt).getTime()
      : null;

    if (aLastActivity === null && bLastActivity === null) {
      // Both lastActivityAt are null, sort by updatedAt
      if (sortOrder === 'oldest') {
        return (
          new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
        );
      } else {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      }
    }

    if (aLastActivity === null) return sortOrder === 'oldest' ? -1 : 1; // a is null, put it at the top if sorting by oldest
    if (bLastActivity === null) return sortOrder === 'oldest' ? 1 : -1; // b is null, put it at the top if sorting by oldest

    // Both lastActivityAt are not null, sort by lastActivityAt
    if (sortOrder === 'oldest') {
      return aLastActivity - bLastActivity;
    } else {
      return bLastActivity - aLastActivity;
    }
  });
};
