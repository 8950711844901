import { FilterContainer } from '@/components/filters/sections/filter-container';
import { CheckboxWithLabel } from '@/components/filters/sections/checkbox-with-label';
import { StageEnum } from '@/pages/crm/lead-stages/types';
import { titleMap } from '@/components/crm/board/board-title';
import { useAuth } from '@/context';

export type AllStageEnum = 'available_lead' | StageEnum;

export const allStages: AllStageEnum[] = [
  'available_lead',
  'assigned_lead',
  'follow_up',
  'front_line_processing',
  'refinancing',
  'refinanced',
  'lost',
];

export interface LeadStageFilterProps {
  selectedStages: AllStageEnum[];
  onSelectStages: (selectedStages: AllStageEnum[]) => void;
}

export const LeadStageFilter = ({
  selectedStages,
  onSelectStages,
}: LeadStageFilterProps) => {
  const handleStageChange = (stage: AllStageEnum) => {
    if (selectedStages.includes(stage)) {
      const newSelectedStages = selectedStages.filter((item) => item !== stage);
      if (newSelectedStages.length > 0) {
        onSelectStages(newSelectedStages);
      }
    } else {
      onSelectStages([...selectedStages, stage]);
    }
  };

  const { userDetails } = useAuth();

  return (
    <FilterContainer
      value="stage"
      title={<div className="text-heading-05 text-dark-dark">Lead Stage</div>}
    >
      <div className="flex-col items-center justify-center space-y-2 px-6">
        {allStages
          .filter(
            (stage) => stage !== 'available_lead' || userDetails.isSuperuser
          )
          .map((stage) => (
            <CheckboxWithLabel
              key={stage}
              label={(titleMap[stage as StageEnum] ?? 'Available').replace(
                'Lead',
                ''
              )}
              checked={selectedStages.includes(stage)}
              onClick={() => handleStageChange(stage)}
            />
          ))}
      </div>
    </FilterContainer>
  );
};
