import { CallableOwners } from '@/pages/admin/property-data/property-table';
import { FilterContainer } from '@/components/filters/sections/filter-container';
import { CheckboxWithLabel } from '@/components/filters/sections/checkbox-with-label';

interface Option {
  value: CallableOwners;
  label: string;
}

const OPTIONS: Option[] = [
  {
    value: 2,
    label: 'Two',
  },
  {
    value: 1,
    label: 'One',
  },
  {
    value: 0,
    label: 'Zero',
  },
];

export interface CallableOwnersFilterProps {
  selectedCallableOwners: CallableOwners[];
  onSelectCallableOwners: (selectedCallableOwners: CallableOwners[]) => void;
}

export const CallableOwnersFilter = ({
  selectedCallableOwners,
  onSelectCallableOwners,
}: CallableOwnersFilterProps) => {
  const handleCallableOwnersChange = (callableOwners: CallableOwners) => {
    if (selectedCallableOwners.includes(callableOwners)) {
      const newCallableOwners = selectedCallableOwners.filter(
        (item) => item !== callableOwners
      );
      if (newCallableOwners.length > 0) {
        onSelectCallableOwners(newCallableOwners);
      }
    } else {
      onSelectCallableOwners([...selectedCallableOwners, callableOwners]);
    }
  };

  return (
    <FilterContainer
      value="callable-owner"
      title={
        <div className="text-heading-05 text-dark-dark">Callable Owners</div>
      }
    >
      <div className="flex-col items-center justify-center space-y-2 px-6">
        {OPTIONS.map(({ value, label }) => (
          <CheckboxWithLabel
            key={value}
            id={`callable-owner-${value}`}
            label={label}
            checked={selectedCallableOwners.includes(value)}
            onClick={() => handleCallableOwnersChange(value)}
          />
        ))}
      </div>
    </FilterContainer>
  );
};
