import { ArrowIcon } from '@/components/map/property-details/icons/arrow-icon';
import { SalesPipeline } from '@/pages/crm/lead-stages/types';
import { StepIcon } from '@/components/map/property-details/icons/step-icon';
import { TickIcon } from '@/components/map/property-details/icons/tick-icon';
import { CloseStopIcon } from '@/components/map/property-details/icons/close-stop-icon';

interface StageDetailsProps {
  salesPipeline?: SalesPipeline;
}

export const StageDetails = ({ salesPipeline }: StageDetailsProps) => {
  const stage = salesPipeline?.stage;

  if (!salesPipeline) {
    return (
      <div className="flex space-x-3.5 items-center">
        <div className="flex space-x-2.5 items-center">
          <StepIcon value={0} active done />
          <div className="text-label-md">Available</div>
        </div>
      </div>
    );
  }

  if (stage === 'lost') {
    return (
      <div className="flex space-x-2.5 items-center">
        <div className="h-8 w-8">
          <CloseStopIcon />
        </div>
        <div className="text-label-md text-navy-navy uppercase">
          Closed: {salesPipeline.lostReasonDescription}
        </div>
      </div>
    );
  }

  if (stage === 'refinanced') {
    return (
      <div className="flex space-x-2.5 items-center">
        <div className="h-8 w-8">
          <TickIcon />
        </div>
        <div className="text-label-md text-navy-navy uppercase">
          Closed: Refinanced
        </div>
      </div>
    );
  }

  return (
    <div className="flex space-x-3.5 items-center">
      <div className="flex space-x-2.5 items-center">
        <StepIcon
          value={1}
          done={stage !== 'assigned_lead'}
          active={stage === 'assigned_lead'}
        />
        <div className="text-label-md">Assigned</div>
      </div>
      <div>
        <ArrowIcon />
      </div>
      <div className="flex space-x-2.5 items-center">
        <StepIcon
          value={2}
          active={stage === 'follow_up'}
          done={!['follow_up', 'assigned_lead'].includes(stage ?? '')}
        />
        <div className="text-label-md text-nowrap">Follow-up</div>
      </div>
      <div>
        <ArrowIcon />
      </div>
      <div className="flex space-x-2.5 items-center">
        <StepIcon
          value={3}
          active={stage === 'front_line_processing'}
          done={
            !['follow_up', 'assigned_lead', 'front_line_processing'].includes(
              stage ?? ''
            )
          }
        />
        <div className="text-label-md text-nowrap">Front line processing</div>
      </div>
      <div>
        <ArrowIcon />
      </div>
      <div className="flex space-x-2.5 items-center">
        <StepIcon
          value={4}
          active={stage === 'refinancing'}
          done={
            ![
              'follow_up',
              'assigned_lead',
              'front_line_processing',
              'refinancing',
            ].includes(stage ?? '')
          }
        />
        <div className="text-label-md text-nowrap">Refinancing</div>
      </div>
      <div>
        <ArrowIcon />
      </div>
      <div className="flex space-x-2.5 items-center">
        <StepIcon value={5} />
        <div className="text-label-md">Refinanced</div>
      </div>
    </div>
  );
};
