import { FilterContainer } from '../sections/filter-container';
import {
  MortgageTypeFilter,
  MortgageTypeFilterProps,
} from '@/components/filters/sections/mortgage-type-filter';
import {
  CallableOwnersFilter,
  CallableOwnersFilterProps,
} from '@/components/filters/sections/callable-owners-filter';
import {
  LeaderCompetitivenessFilter,
  LeaderCompetitivenessFilterProps,
} from '@/components/filters/sections/lender-ranking-filter';
import {
  OpportunityValueFilter,
  OpportunityValueFilterProps,
} from '@/components/filters/sections/opportunity-value-filter';
import {
  StateFilter,
  StateFilterProps,
} from '@/components/filters/sections/state-filter';
import {
  InterestRateFilter,
  AprFilterProps,
} from '@/components/filters/sections/interest-rate-filter';
import {
  MortgageBalanceFilter,
  MortgageBalanceFilterProps,
} from '@/components/filters/sections/mortgage-balance-filter';
import {
  BuildYearFilter,
  BuildYearFilterProps,
} from '@/components/filters/sections/build-year-filter';
import {
  SaleDateFilter,
  SaleDateFilterProps,
} from '@/components/filters/sections/sale-date-filter';
import {
  LeadStageFilter,
  LeadStageFilterProps,
} from '@/components/filters/sections/lead-stage-filter';
import {
  EmailableOwnersFilter,
  EmailableOwnersFilterProps,
} from '@/components/filters/sections/emailable-owners-filter';
import {
  DoNotMailFilter,
  DoNotMailFilterProps,
} from '@/components/filters/sections/do-not-mail-filter';
import {
  AssignedToFilter,
  AssignedToFilterProps,
} from '@/components/filters/sections/assigned-to-filter';
import { useAuth } from '@/context';

export interface MapFilterProps
  extends MortgageTypeFilterProps,
    CallableOwnersFilterProps,
    LeaderCompetitivenessFilterProps,
    StateFilterProps,
    OpportunityValueFilterProps,
    AprFilterProps,
    MortgageBalanceFilterProps,
    BuildYearFilterProps,
    SaleDateFilterProps,
    LeadStageFilterProps,
    EmailableOwnersFilterProps,
    DoNotMailFilterProps,
    AssignedToFilterProps {}

export const MapFilter = ({
  selectedMortgageTypes,
  onSelectMortgageTypes,
  selectedAprRange,
  onSelectAprRange,
  selectedMortgageBalanceRange,
  onSelectMortgageBalanceRange,
  selectedBuildYearRange,
  onSelectBuildYearRange,
  selectedCallableOwners,
  onSelectCallableOwners,
  selectedLeaderCompetitiveness,
  onSelectLeaderCompetitiveness,
  selectedStates,
  onSelectStates,
  selectedOpportunityValues,
  onSelectOpportunityValues,
  selectedSaleDateRange,
  onSelectSaleDateRange,
  selectedStages,
  onSelectStages,
  selectedEmailableOwners,
  onSelectEmailableOwners,
  selectedDoNotMails,
  onSelectDoNotMails,
  selectedUserId,
  onSelectedUserId,
}: MapFilterProps) => {
  const { userDetails } = useAuth();

  return (
    <div className="w-[280px] rounded-lg shadow">
      <FilterContainer
        value="filter"
        title={<div className="text-heading-04 text-dark-dark">Filter</div>}
        triggerClassName="bg-light-light rounded-t-lg"
        itemClassName="rounded-b-lg"
      >
        {userDetails.isSuperuser && (
          <AssignedToFilter
            selectedUserId={selectedUserId}
            onSelectedUserId={onSelectedUserId}
          />
        )}
        <LeadStageFilter
          selectedStages={selectedStages}
          onSelectStages={onSelectStages}
        />
        <MortgageTypeFilter
          selectedMortgageTypes={selectedMortgageTypes}
          onSelectMortgageTypes={onSelectMortgageTypes}
        />

        <SaleDateFilter
          selectedSaleDateRange={selectedSaleDateRange}
          onSelectSaleDateRange={onSelectSaleDateRange}
        />

        <InterestRateFilter
          selectedAprRange={selectedAprRange}
          onSelectAprRange={onSelectAprRange}
        />

        <MortgageBalanceFilter
          selectedMortgageBalanceRange={selectedMortgageBalanceRange}
          onSelectMortgageBalanceRange={onSelectMortgageBalanceRange}
        />

        <BuildYearFilter
          selectedBuildYearRange={selectedBuildYearRange}
          onSelectBuildYearRange={onSelectBuildYearRange}
        />

        <OpportunityValueFilter
          selectedOpportunityValues={selectedOpportunityValues}
          onSelectOpportunityValues={onSelectOpportunityValues}
        />

        <StateFilter
          selectedStates={selectedStates}
          onSelectStates={onSelectStates}
        />

        <CallableOwnersFilter
          selectedCallableOwners={selectedCallableOwners}
          onSelectCallableOwners={onSelectCallableOwners}
        />

        <EmailableOwnersFilter
          selectedEmailableOwners={selectedEmailableOwners}
          onSelectEmailableOwners={onSelectEmailableOwners}
        />

        <DoNotMailFilter
          selectedDoNotMails={selectedDoNotMails}
          onSelectDoNotMails={onSelectDoNotMails}
        />

        <LeaderCompetitivenessFilter
          selectedLeaderCompetitiveness={selectedLeaderCompetitiveness}
          onSelectLeaderCompetitiveness={onSelectLeaderCompetitiveness}
        />
      </FilterContainer>
    </div>
  );
};
