import { PropsWithChildren, ReactNode } from 'react';
import {
  EmailIcon,
  IdncIcon,
  InfoIcon,
  ShieldIcon,
  UserIcon,
} from '@/components/icons';
import { useAuth } from '@/context';
import { OptOutIcon } from '@/pages/settings/common/icons/opt-out-icon';
import { SidebarLink } from '@/pages/settings/common/sidebar-link';
import { DownloadIcon } from './icons/download-icon';
import { RunHistoryIcon } from '@/pages/settings/common/icons/run-history-icon';
import { ApiUsageIcon } from '@/pages/settings/common/icons/api-usage-icon';
import { SettingIcon } from '@/pages/settings/common/icons/setting-icon';
import { TagIcon } from '@/components/map/property-details/icons/tag-icon';
import { AreaChartIcon } from 'lucide-react';

interface SidebarWithChildrenProps extends PropsWithChildren {
  children: ReactNode;
  currentPage:
    | 'profile'
    | 'password'
    | 'user-management'
    | 'idnc'
    | 'email-templates'
    | 'email-opt-out-list'
    | 'property-data'
    | 'dnc-compliance-log'
    | 'platform-setting'
    | 'user-performance'
    | 'manual-data-import'
    | 'not-available';
}

export const Sidebar = ({
  children,
  currentPage,
}: SidebarWithChildrenProps) => {
  const { userDetails } = useAuth();

  return (
    <div className="main">
      <div className="w-[280px] rounded-lg shadow bg-white overflow-y-auto">
        <div className="text-heading-04 text-dark-dark flex-1 justify-between font-medium transition-all group [&[data-state=open]>svg]:rotate-180 w-full px-6 py-2 h-14 flex items-center hover:no-underline bg-light-light rounded-t-lg">
          Settings
        </div>

        <div className="flex flex-col py-4 px-6 overflow-y-auto">
          <SidebarLink
            active={currentPage === 'profile'}
            icon={<UserIcon />}
            title="Profile"
            href="/settings/profile"
          />
          <SidebarLink
            active={currentPage === 'password'}
            icon={<ShieldIcon />}
            title="Security & Password"
            href="/settings/password"
          />

          <hr className="border-navy-navy my-4" />

          {userDetails.isSuperuser && (
            <>
              <SidebarLink
                active={currentPage === 'user-management'}
                icon={<UserIcon />}
                title="User Management"
                href="/admin/users"
              />
              <SidebarLink
                active={currentPage === 'user-performance'}
                icon={<AreaChartIcon />}
                title="User Performance"
                href="/admin/user-performance"
              />
              <SidebarLink
                active={currentPage === 'property-data'}
                icon={<InfoIcon />}
                title="Property Data"
                href="/admin/property-data"
              />

              <div className="text-paragraph-md flex p-3 text-paragraph-md text-navy-navy space-x-1.5 items-center">
                Data Pipeline
              </div>
              <div className="pl-2">
                <SidebarLink
                  active={currentPage === 'manual-data-import'}
                  icon={<DownloadIcon />}
                  title="Manual Data Import"
                  href="/admin/manual-data-import"
                />
                <SidebarLink
                  active={currentPage === 'not-available'}
                  icon={<RunHistoryIcon />}
                  title="Run History"
                  href="/admin/not-available"
                />
                <SidebarLink
                  active={currentPage === 'not-available'}
                  icon={<ApiUsageIcon />}
                  title="API Usage Monitor"
                  href="/admin/not-available"
                />
              </div>

              <div className="text-paragraph-md flex p-3 text-paragraph-md text-navy-navy space-x-1.5 items-center">
                Email Functionalities
              </div>
              <div className="pl-2">
                <SidebarLink
                  active={currentPage === 'email-templates'}
                  icon={<EmailIcon />}
                  title="Email Templates"
                  href="/admin/email-templates"
                />
                <SidebarLink
                  active={currentPage === 'email-opt-out-list'}
                  icon={<OptOutIcon />}
                  title="Email Opt-Out List"
                  href="/admin/email-opt-out-list"
                />
              </div>

              <div className="text-paragraph-md flex p-3 text-paragraph-md text-navy-navy space-x-1.5 items-center">
                Call Functionalities
              </div>
              <div className="pl-2">
                <SidebarLink
                  active={currentPage === 'idnc'}
                  icon={<IdncIcon />}
                  title="Internal DNC List"
                  href="/admin/idnc"
                />
                <SidebarLink
                  active={currentPage === 'dnc-compliance-log'}
                  icon={<ShieldIcon />}
                  title="DNC Compliance Log"
                  href="/admin/dnc-compliance-log"
                />
                <SidebarLink
                  active={currentPage === 'not-available'}
                  icon={<OptOutIcon />}
                  title="Call Activity Log"
                  href="/admin/not-available"
                />
                <SidebarLink
                  active={currentPage === 'not-available'}
                  icon={<TagIcon />}
                  title="Sales Origination Phone Numbers"
                  href="/admin/dnc-compliance-log"
                />
              </div>

              <SidebarLink
                active={currentPage === 'platform-setting'}
                icon={<SettingIcon />}
                title="Platform Setting"
                href="/admin/platform-setting"
              />
            </>
          )}
        </div>
      </div>
      <div
        className="overflow-x-auto"
        style={{ width: 'calc(100vw - 300px)', height: 'calc(100vh - 110px)' }}
      >
        <div className="rounded-lg bg-transparent overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
};
