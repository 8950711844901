import { Card, CardContent } from '@/components/ui/card';

import { Loader } from '@/components';
import {
  ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { DncComplianceLogEntry } from '@/pages/admin/dnc-compliance-log/types';
import { useEffect, useState } from 'react';
import { DataTableColumnHeader } from '@/components/table/data-table-column-header';
import { ReactTable } from '@/components/table/react-table';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/use-api';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle, DownloadIcon } from 'lucide-react';
import { DncRegisteredCell } from '@/pages/admin/dnc-compliance-log/dnc-registered-cell';
import { humanize } from '@/utils';
import { SearchIcon } from '@/pages/crm/lead-stages/icons/search-icon';
import { Button } from '@/components/ui/button';
import { getPtDatetimeFormattedString, toISODate } from '@/utils/date';
import { exportCsv } from '@/utils/csv';
import { searchDncComplianceLog } from '@/pages/admin/dnc-compliance-log/utils';
import { Sidebar } from '@/pages/settings/common/sidebar';

export const DncComplianceLog = () => {
  const { getRequest } = useApi();

  const { isPending, error, data } = useQuery<DncComplianceLogEntry[]>({
    queryKey: ['dncComplianceLog'],
    queryFn: () => getRequest('/api/external_api_logs/dnc_compliance_logs'),
  });

  const [search, setSearch] = useState<string>('');
  const [filteredData, setFilteredData] = useState<
    DncComplianceLogEntry[] | null
  >(null);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  const columns: ColumnDef<DncComplianceLogEntry>[] = [
    {
      accessorKey: 'timestamp',
      meta: {
        title: 'Check Timestamp',
        minWidth: 200,
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => (
        <div>
          {getPtDatetimeFormattedString(row.getValue('timestamp') as string)}
        </div>
      ),
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'phone',
      meta: {
        title: 'Phone Number',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => <div>{row.getValue('phone')}</div>,
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'apiInterface',
      meta: {
        title: 'API Interface',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => <div>{row.getValue('apiInterface')}</div>,
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'status',
      meta: {
        title: 'Phone Status',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => <div>{humanize(row.getValue('status'))}</div>,
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'iscell',
      meta: {
        title: 'Is Cell',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        const value = row.getValue('iscell');

        if (value === 'Y') {
          return 'Yes';
        } else if (value === 'N') {
          return 'No';
        }

        return value ?? '';
      },
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'federalDncRegistered',
      meta: {
        title: 'Federal DNC\nRegistered',
        minWidth: 180,
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return (
          <DncRegisteredCell value={row.getValue('federalDncRegistered')} />
        );
      },
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'stateDncRegistered',
      meta: {
        title: 'State DNC\nRegistered',
        minWidth: 150,
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return <DncRegisteredCell value={row.getValue('stateDncRegistered')} />;
      },
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'dmaDncRegistered',
      meta: {
        title: 'DMA DNC\nRegistered',
        minWidth: 150,
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        return <DncRegisteredCell value={row.getValue('dmaDncRegistered')} />;
      },
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'incomingIpAddress',
      meta: {
        title: 'Incoming \nIP Address',
        minWidth: 180,
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'outgoingIpAddress',
      meta: {
        title: 'Outgoing \nIP Address',
        minWidth: 180,
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      enableSorting: true,
      enableHiding: false,
    },
  ];

  useEffect(() => {
    if (data) {
      setFilteredData(searchDncComplianceLog(search, data));
    }
  }, [search, data]);

  const table = useReactTable<DncComplianceLogEntry>({
    data: filteredData ?? [],
    columns,
    state: {
      sorting,
      columnVisibility,
      pagination,
    },
    enableRowSelection: true,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  return (
    <Sidebar currentPage="dnc-compliance-log">
      <div className="flex flex-col space-y-4">
        <div>
          <div className="text-heading-01">DNC Compliance Log</div>
        </div>
        <Card>
          <CardContent>
            <div className="space-y-4 mt-6">
              <div className="flex justify-between items-center w-full px-6 py-2 bg-navy-navy rounded-lg">
                <div className="relative w-full">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">
                    <SearchIcon />
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="h-8 w-[420px] text-label-md bg-gray-50 border border-gray-300 rounded ps-9 p-1 normal-case"
                    placeholder="SEARCH BY PHONE, IP ADDRESSES..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div>
                  <Button
                    variant="secondary"
                    className="flex space-x-2"
                    onClick={() => {
                      const formattedDate = toISODate(new Date());
                      exportCsv(`dnc-compliance-log_${formattedDate}`, table);
                    }}
                  >
                    <span>
                      <DownloadIcon className="h-4 w-4" />
                    </span>
                    <span>Download as CSV</span>
                  </Button>
                </div>
              </div>
              <div>
                {isPending && <Loader />}
                {filteredData && <ReactTable table={table} />}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </Sidebar>
  );
};
