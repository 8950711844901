import {
  CalendarIcon,
  ExternalLinkIcon,
  NoteIcon,
  TaskIcon,
  BalanceIcon,
  EmailIcon,
} from '@/components/icons';
import { Item } from '@/components/map/property-details/item';
import { formatRoundedMoney, isBlank } from '@/utils';
import { SalesPipeline } from '@/pages/crm/lead-stages/types';
import { CommonProperty } from '@/pages/admin/property-data/property-table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { isFirstOwnerAvailable, isSecondOwnerAvailable } from '@/model/utils';
import { DueDatetimeLabel } from '@/components/map/property-details/due-datetime-label';
import { getMortgageType, getOwnerEmail } from '@/components/crm/board/utils';
import { getPtDatetimeFormattedString } from '@/utils/date';

export interface BoardCardProps {
  isSuperuser?: boolean;
  salesPipeline: SalesPipeline;

  onShowDetails?(): void;
  openEmailDialog(salesPipeline: SalesPipeline): void;
}

const getFirstOwnerName = (salesPipeline: SalesPipeline) => {
  if (salesPipeline.manualProperty) {
    const fullName = salesPipeline.manualProperty.firstCurrentOwnerFullName;
    if (fullName) {
      const nameParts = fullName.split(' ');
      return nameParts.length > 1 ? nameParts[1] : fullName;
    }
    return 'N/A';
  }

  if (salesPipeline.property && isFirstOwnerAvailable(salesPipeline.property)) {
    return salesPipeline.property.firstCurrentOwnerLastName ?? 'N/A';
  }

  if (
    salesPipeline.property &&
    isSecondOwnerAvailable(salesPipeline.property)
  ) {
    return salesPipeline.property.secondCurrentOwnerLastName ?? 'N/A';
  }

  return 'N/A';
};

const getInitials = (fullName: string): string => {
  return fullName
    .split(' ')
    .map((word) => word.charAt(0))
    .join('');
};

export const BoardCard = ({
  onShowDetails,
  salesPipeline,
  isSuperuser,
  openEmailDialog,
}: BoardCardProps) => {
  const commonProperty =
    salesPipeline.property ?? (salesPipeline.manualProperty as CommonProperty);
  const mortgageType = getMortgageType(salesPipeline);
  const firstOwnerFullName = getFirstOwnerName(salesPipeline);

  const lastActivityAt = salesPipeline.lastActivityAt
    ? getPtDatetimeFormattedString(salesPipeline.lastActivityAt)
    : 'N/A';
  const ownerEmail = getOwnerEmail(salesPipeline);

  return (
    <div className="w-full py-3.5 bg-light-tint rounded-lg h-full">
      <div className="flex items-center justify-between px-3">
        <div className="flex space-x-2">
          <div className="px-1.5 py-[3px] bg-blue-tint rounded justify-start items-center gap-2.5 inline-flex">
            <div className="text-paragraph-sm text-blue-shade">
              IR:{' '}
              {commonProperty?.originalMortgageInterestRate
                ? `${parseFloat(
                    commonProperty?.originalMortgageInterestRate?.toString() ??
                      '0.0'
                  )}%`
                : 'N/A'}
            </div>
          </div>
          {mortgageType && (
            <div className="px-1.5 py-[3px] bg-green-tint rounded justify-start items-center gap-2.5 inline-flex">
              <div className="text-paragraph-sm text-green-shade">
                {mortgageType}
              </div>
            </div>
          )}
          {isSuperuser && salesPipeline.assignedUserFullName && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="px-1.5 py-[3px] bg-yellow-tint rounded justify-start items-center gap-2.5 inline-flex">
                    <div className="text-paragraph-sm text-yellow-shade">
                      {getInitials(salesPipeline.assignedUserFullName)}
                    </div>
                  </div>
                </TooltipTrigger>
                <TooltipContent className="whitespace-pre-wrap">
                  {salesPipeline.assignedUserFullName}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
        <div className="flex items-center space-x-2">
          {ownerEmail && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <button
                    className="m-auto"
                    onClick={() => openEmailDialog(salesPipeline)}
                  >
                    <EmailIcon active={salesPipeline.emailClientOpened} />
                  </button>
                </TooltipTrigger>
                <TooltipContent className="whitespace-pre-wrap">
                  <a href={`mailto:${ownerEmail}`}>{ownerEmail}</a>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
          <button onClick={() => onShowDetails?.()} className="m-auto">
            <ExternalLinkIcon />
          </button>
        </div>
      </div>
      <div className="flex justify-between x-3 py-3 px-3 even:bg-white odd:bg-slate-50 space-x-3 text-paragraph-sm text-dark-dark">
        <div className="flex space-x-2 items-center">
          <div className="w-6 text-dark-dark">
            <img src="/assets/images/avatar.png" alt="Avatar Icon" />
          </div>
          <div className="text-dark-tint text-paragraph-sm">
            {firstOwnerFullName}
          </div>
        </div>
        <div className="text-dark-dark text-paragraph-sm text-right flex space-x-1.5">
          {salesPipeline.task && !salesPipeline.taskCompleted && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="m-auto">
                    <TaskIcon dueDatetime={salesPipeline.taskDueDatetime} />
                  </div>
                </TooltipTrigger>
                <TooltipContent className="max-w-[380px] p-6 bg-yellow-tint">
                  <div className="flex flex-col space-y-3 text-left">
                    <div className="flex space-x-2 items-center justify-start pt-1 text-yellow-shade">
                      <div className="text-yellow-shade">
                        <TaskIcon
                          dueDatetime={salesPipeline.taskDueDatetime}
                          colorClassName="text-yellow-shade"
                        />
                      </div>
                      <DueDatetimeLabel
                        colorClassName="text-yellow-shade"
                        taskDueDatetime={salesPipeline.taskDueDatetime}
                      />
                    </div>
                    <div className="whitespace-pre-wrap line-clamp-8 text-paragraph-md text-yellow-shade">
                      {salesPipeline.task}
                    </div>
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
          {salesPipeline.notes && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="m-auto">
                    <NoteIcon />
                  </div>
                </TooltipTrigger>
                <TooltipContent className="max-w-[380px] bg-blue-tint p-6">
                  <div className="flex flex-col space-y-3 text-left">
                    {salesPipeline.notesUpdatedAt && (
                      <div className="flex space-x-2 items-center justify-start">
                        <div>
                          <NoteIcon />
                        </div>
                        <div className="text-paragraph-md text-navy-navy">
                          Last Updated:{' '}
                          {getPtDatetimeFormattedString(
                            salesPipeline.notesUpdatedAt
                          )}
                        </div>
                      </div>
                    )}
                    <div className="whitespace-pre-wrap line-clamp-8 text-paragraph-md text-navy-navy">
                      {salesPipeline.notes}
                    </div>
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      </div>
      <ul>
        <li className="flex justify-between items-center px-3 py-1.5 even:bg-white odd:bg-slate-50 space-x-3">
          <div className="flex space-x-2 items-center">
            <div className="text-dark-tint text-paragraph-sm">
              {isBlank(commonProperty?.situsFullAddress)
                ? 'N/A'
                : commonProperty?.situsFullAddress}
            </div>
          </div>
          <div className="text-dark-dark text-paragraph-sm text-right"></div>
        </li>
        <Item
          label="Est. Mtg. Bal."
          value={
            commonProperty.originalMortgageAmount
              ? formatRoundedMoney(
                  parseInt(
                    commonProperty.originalMortgageAmount?.toString() ?? '0'
                  )
                )
              : 'N/A'
          }
          icon={<BalanceIcon />}
        />
        <Item
          label="Last Activity"
          value={lastActivityAt}
          icon={<CalendarIcon />}
        />
      </ul>
    </div>
  );
};
