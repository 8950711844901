import { FilterContainer } from '@/components/filters/sections/filter-container';
import { CheckboxWithLabel } from '@/components/filters/sections/checkbox-with-label';
import { PinIcon } from '@/components/icons';
import { quartileColors } from '@/utils/colors';

export type OpportunityValue = 1 | 2 | 3 | 4;

const AllOpportunityValues: OpportunityValue[] = [4, 3, 2, 1];

export interface OpportunityValueFilterProps {
  selectedOpportunityValues: OpportunityValue[];
  onSelectOpportunityValues: (
    selectedOpportunityValues: OpportunityValue[]
  ) => void;
}

export const OpportunityValueFilter = ({
  selectedOpportunityValues,
  onSelectOpportunityValues,
}: OpportunityValueFilterProps) => {
  const handleOpportunityValuesChange = (
    opportunityValue: OpportunityValue
  ) => {
    if (selectedOpportunityValues.includes(opportunityValue)) {
      const newCallableOwners = selectedOpportunityValues.filter(
        (item) => item !== opportunityValue
      );
      if (newCallableOwners.length > 0) {
        onSelectOpportunityValues(newCallableOwners);
      }
    } else {
      onSelectOpportunityValues([
        ...selectedOpportunityValues,
        opportunityValue,
      ]);
    }
  };

  const isAllSelected = AllOpportunityValues.every((value) =>
    selectedOpportunityValues.includes(value)
  );

  return (
    <FilterContainer
      value="opportunity-value"
      title={
        <div className="text-heading-05 text-dark-dark">Opportunity Value</div>
      }
    >
      <div className="flex-col items-center justify-center space-y-4 px-6">
        <CheckboxWithLabel
          label="ALL"
          checked={isAllSelected}
          onClick={() =>
            onSelectOpportunityValues(isAllSelected ? [] : AllOpportunityValues)
          }
        />
        <div className="flex space-x-8">
          {AllOpportunityValues.map((opportunityValue) => (
            <button
              key={opportunityValue}
              className="flex flex-col items-center space-y-1"
              onClick={() => handleOpportunityValuesChange(opportunityValue)}
            >
              <div
                style={{
                  color: quartileColors[opportunityValue],
                  opacity: selectedOpportunityValues.includes(opportunityValue)
                    ? '1'
                    : '0.1',
                }}
              >
                <PinIcon />
              </div>
              <div className="text-heading-05 text-dark-dark">
                {'$'.repeat(opportunityValue)}
              </div>
            </button>
          ))}
        </div>
      </div>
    </FilterContainer>
  );
};
