import { useState } from 'react';
import { FilterContainer } from '@/components/filters/sections/filter-container';
import { CheckboxWithLabel } from '@/components/filters/sections/checkbox-with-label';
import { stateAbbreviations, stateMap } from '@/utils/address';

export interface StateFilterProps {
  selectedStates: string[];
  onSelectStates: (selectedCounties: string[]) => void;
}

export const StateFilter = ({
  selectedStates,
  onSelectStates,
}: StateFilterProps) => {
  const [showAll, setShowAll] = useState(false);

  const handleStatesChange = (state: string) => {
    if (selectedStates.includes(state)) {
      const newSelectedStates = selectedStates.filter((item) => item !== state);
      if (newSelectedStates.length > 0) {
        onSelectStates(newSelectedStates);
      }
    } else {
      onSelectStates([...selectedStates, state]);
    }
  };

  const isAllSelected = stateAbbreviations.every((value) =>
    selectedStates.includes(value)
  );

  return (
    <FilterContainer
      value="state"
      title={<div className="text-heading-05 text-dark-dark">Region</div>}
    >
      <div className="flex-col items-center justify-center space-y-2 px-6">
        <CheckboxWithLabel
          label="National"
          checked={isAllSelected}
          onClick={() =>
            onSelectStates(isAllSelected ? [] : stateAbbreviations)
          }
        />
        {!isAllSelected && (
          <button
            className="text-blue-500"
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? 'Show Selected Only' : 'Show All'}
          </button>
        )}
        {stateAbbreviations.map((stateAbbreviation) => {
          const isSelected = selectedStates.includes(stateAbbreviation);
          if (!showAll && !isSelected) return null;
          return (
            <CheckboxWithLabel
              key={stateAbbreviation}
              label={stateMap[stateAbbreviation] ?? stateAbbreviation}
              checked={isSelected}
              onClick={() => handleStatesChange(stateAbbreviation)}
            />
          );
        })}
      </div>
    </FilterContainer>
  );
};
