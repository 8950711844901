import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { EditIcon } from '@/components/icons';
import { Owner, OwnerForm } from '@/components/map/property-details/types';
import { useCallback, useEffect, useState } from 'react';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';

interface EditContactDialogProps {
  owner: Owner;
  position: 1 | 2;
  updateSalesPipelineOwner?(params: {
    position: 1 | 2;
    ownerForm: OwnerForm;
  }): Promise<void>;
}

export const EditContactDialog = ({
  owner,
  position,
  updateSalesPipelineOwner,
}: EditContactDialogProps) => {
  const [ownerForm, setOwnerForm] = useState<OwnerForm>({ ...owner });
  const [updating, setUpdating] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (!updating) {
      setOwnerForm({ ...owner });
    }
  }, [owner, updating]);

  const handleUpdate = useCallback(
    async (position: 1 | 2) => {
      setUpdating(true);

      try {
        await updateSalesPipelineOwner?.({ position, ownerForm });
        setDialogOpen(false);
      } finally {
        setUpdating(false);
      }
    },
    [updateSalesPipelineOwner, ownerForm]
  );

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <button className="w-6 h-6" onClick={() => setDialogOpen(true)}>
          <EditIcon />
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Edit Contact Info</DialogTitle>
        </DialogHeader>
        <form
          className="space-y-3"
          onSubmit={async (e) => {
            e.preventDefault();
            await handleUpdate(position);
          }}
        >
          <div className="space-y-1">
            <Label htmlFor="fullName">Full name</Label>
            <Input
              id="fullName"
              name="fullName"
              required
              autoFocus
              value={ownerForm.fullName ?? ''}
              onChange={(e) => {
                setOwnerForm((prevState) => ({
                  ...prevState,
                  fullName: e.target.value,
                }));
              }}
            />
          </div>
          <div className="space-y-1">
            <Label htmlFor="phoneNumber">Phone number</Label>
            <Input
              id="phoneNumber"
              name="phoneNumber"
              autoFocus
              value={ownerForm.phoneNumber ?? ''}
              onChange={(e) => {
                setOwnerForm((prevState) => ({
                  ...prevState,
                  phoneNumber: e.target.value,
                }));
              }}
            />
          </div>
          <div className="space-y-1">
            <Label htmlFor="phoneNumber2">Phone number 2</Label>
            <Input
              id="phoneNumber2"
              name="phoneNumber2"
              value={ownerForm.phoneNumber2 ?? ''}
              onChange={(e) => {
                setOwnerForm((prevState) => ({
                  ...prevState,
                  phoneNumber2: e.target.value,
                }));
              }}
            />
          </div>
          <div className="space-y-1">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              name="email"
              type="email"
              value={ownerForm.email ?? ''}
              onChange={(e) => {
                setOwnerForm((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }));
              }}
            />
          </div>
          <DialogFooter>
            <Button type="submit" disabled={updating}>
              {updating ? 'Updating' : 'Update'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
