import { SalesPipeline } from '@/pages/crm/lead-stages/types';
import { MORTGAGE_TYPE_MAP } from '@/components/crm/board/board-column';
import { formatDateVerbose } from '@/utils/date';
import { capitalize, stateMap } from '@/utils';

export const AllEmailFields = [
  'user_full_name',
  'owners',
  'address',
  'county',
  'mortgage_lender',
  'street',
  'city',
  'state',
  'state_abbr',
  'zip',
  'interest_rate',
  'mortgage_type',
  'est_mortgage_balance',
  'last_armslength_sale_date',
  'home_build_year',
] as const;

type EmailField = (typeof AllEmailFields)[number];

const getZipFromAddress = (address: string): string | null => {
  const zipRegex = /\b\d{5}(?:-\d{4})?\b/;
  const match = address.match(zipRegex);
  return match ? match[0] : null;
};

export const getFieldMapping = (
  userFullName: string,
  salesPipeline?: SalesPipeline
): Record<EmailField, string> => {
  const property = salesPipeline?.property;

  const owners = [
    `${property?.firstCurrentOwnerFirstName ?? ''} ${property?.firstCurrentOwnerLastName ?? ''}`.trim(),
    `${property?.secondCurrentOwnerFirstName ?? ''} ${property?.secondCurrentOwnerLastName ?? ''}`.trim(),
  ]
    .filter((name) => name !== '')
    .join(' & ');

  return {
    user_full_name: capitalize(userFullName),
    owners: capitalize(owners || ''),
    address: capitalize(property?.situsFullAddress ?? ''),
    county: capitalize(property?.county ?? ''),
    mortgage_lender: property?.originalMortgageLender ?? '',
    street: capitalize(property?.situsStreetAddress ?? ''),
    city: capitalize(property?.situsCity ?? ''),
    state_abbr: property?.state ?? '',
    state: stateMap[property?.state ?? ''] ?? property?.state ?? '',
    zip: getZipFromAddress(property?.situsFullAddress ?? '') ?? '',
    interest_rate: `${property?.originalMortgageInterestRate}`,
    mortgage_type: MORTGAGE_TYPE_MAP[`${property?.originalMortgageType}`],
    est_mortgage_balance: property?.originalMortgageAmount?.toString() ?? '0',
    last_armslength_sale_date: formatDateVerbose(
      property?.recordDateLastArmsLengthSale
    ),
    home_build_year: property?.yearBuilt?.toString() ?? '',
  };
};

export const generateEmailContent = (
  emailBodyTemplate: string,
  fieldMapping: Record<EmailField, string>
) => {
  return AllEmailFields.reduce((acc, field) => {
    return acc.replace(`<${field}>`, fieldMapping[field]);
  }, emailBodyTemplate);
};
