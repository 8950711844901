import { FilterContainer } from '@/components/filters/sections/filter-container';
import { useQuery } from '@tanstack/react-query';
import { UserListResponse } from '@/pages';
import { useApi } from '@/hooks/use-api';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

export interface AssignedToFilterProps {
  selectedUserId?: number;
  onSelectedUserId: (selectedUserId?: number) => void;
}

export const AssignedToFilter = ({
  selectedUserId,
  onSelectedUserId,
}: AssignedToFilterProps) => {
  const { getRequest } = useApi();
  const { data: userList } = useQuery<UserListResponse>({
    queryKey: ['usersData'],
    queryFn: () => getRequest('/api/users'),
  });

  const users = userList?.data;

  return (
    <FilterContainer
      value="assigned-to"
      title={<div className="text-heading-05 text-dark-dark">Assigned To</div>}
    >
      <div className="flex px-6 mt-1 w-full">
        <Select
          onValueChange={(value) => {
            onSelectedUserId?.(value === 'all' ? undefined : parseInt(value));
          }}
          value={selectedUserId?.toString() ?? 'all'}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select a user" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All</SelectItem>
            {users?.map((user) => (
              <SelectItem value={user.id.toString()} key={user.id}>
                {user.firstName} {user.lastName}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </FilterContainer>
  );
};
