import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { UserPerformance } from '@/pages/admin/user-performance/types';

interface UserPerformanceProps {
  users: UserPerformance[];
}

export const UserPerformanceTable = ({ users }: UserPerformanceProps) => {
  return (
    <div className="relative w-full overflow-auto">
      <Table className="table">
        <TableHeader>
          <TableRow>
            <TableHead className="w-40">Full Name</TableHead>
            <TableHead className="w-40">Email</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>User Type</TableHead>
            <TableHead>Assigned Leads Cap</TableHead>
            <TableHead>Notes Edited</TableHead>
            <TableHead>Tasks Edited</TableHead>
            <TableHead>Leads Assigned</TableHead>
            <TableHead>Login</TableHead>
            <TableHead>View Map</TableHead>
            <TableHead>View Market</TableHead>
            <TableHead>View LO Funnel</TableHead>
            <TableHead>View LO CRM</TableHead>
            <TableHead>View Property Card</TableHead>
            <TableHead>View LO Team</TableHead>
            <TableHead>View Admin</TableHead>
            <TableHead>
              <span className="sr-only">Actions</span>
            </TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell className="font-medium">
                {user.firstName} {user.lastName}
              </TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                {user.isActive ? (
                  <Badge variant="green">Active</Badge>
                ) : (
                  <Badge variant="red">Removed</Badge>
                )}
              </TableCell>
              <TableCell>
                {user.isSuperuser ? 'Admin' : 'Loan Officer'}
              </TableCell>
              <TableCell>{user.isSuperuser ? 'No Limit' : '300'}</TableCell>
              <TableCell>{user.editNotesCount}</TableCell>
              <TableCell>{user.editTaskCount}</TableCell>
              <TableCell>{user.assignedLeads}</TableCell>
              <TableCell>{user.userLoginCount}</TableCell>
              <TableCell>{user.viewMapCount}</TableCell>
              <TableCell>{user.viewMarketCount}</TableCell>
              <TableCell>{user.viewSalesFunnelCount}</TableCell>
              <TableCell>{user.viewLeadStagesCount}</TableCell>
              <TableCell>{user.viewPropertyCardCount}</TableCell>
              <TableCell>{user.viewTeamCount}</TableCell>
              <TableCell>{user.viewAdminCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
