import type { EChartsOption } from 'echarts';
import { ECharts } from '@/components/charts/market-opportunity-chart/e-charts';

interface AggregateSumChartRow {
  date: string;
  leadFollowUp: number;
  leadRefinancedAmount: number;
}

interface AggregateSumChartProps {
  data: AggregateSumChartRow[];
}

export const AggregateSumChart = ({ data }: AggregateSumChartProps) => {
  const option: EChartsOption = {
    xAxis: {
      type: 'category',
      data: data.map((row) => row.date),
      axisLabel: {
        align: 'left',
      },
    },
    yAxis: [
      {
        type: 'value',
        name: 'Follow-Up Leads',
        position: 'left',
      },
      {
        type: 'value',
        name: 'Aggregate Sum of Refinanced Mortgage Balances',
        position: 'right',
        axisLabel: {
          formatter: (
            value: string | number | Date | (string | number | Date)[]
          ) => {
            if (typeof value === 'number') {
              if (value >= 1000000) {
                return `${(value / 1000000).toFixed(1)}M`;
              } else if (value >= 1000) {
                return `${(value / 1000).toFixed(1)}K`;
              }
              return value.toString();
            }
            return value.toString();
          },
        },
      },
    ],
    series: [
      {
        name: 'Follow-Up Leads',
        type: 'bar',
        data: data.map((row) => row.leadFollowUp),
        itemStyle: {
          color: '#FFA53C',
        },
      },
      {
        name: 'Aggregate Sum of Refinanced Mortgage Balances',
        type: 'line',
        yAxisIndex: 1,
        data: data.map((row) => row.leadRefinancedAmount),
        itemStyle: {
          color: '#056B3A',
        },
        tooltip: {
          valueFormatter: (value) => {
            if (typeof value === 'number') {
              if (value >= 1000000) {
                return `${(value / 1000000).toFixed(1)}M`;
              } else if (value >= 1000) {
                return `${(value / 1000).toFixed(1)}K`;
              }
              return value.toString();
            }
            return value?.toString() ?? '';
          },
        },
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
  };

  return (
    <div className="h-[400px] mt-6">
      {data.length === 0 ? (
        <div style={{ height: 450 }}>Loading...</div>
      ) : (
        <ECharts option={option} style={{ height: 450 }} />
      )}
    </div>
  );
};
