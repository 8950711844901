import { useQuery } from '@tanstack/react-query';
import {
  SortingState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState,
  PaginationState,
} from '@tanstack/react-table';
import { AlertCircle } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { useEffect, useState } from 'react';
import { Loader } from '@/components';
import { useApi } from '@/hooks/use-api';
import { isLocal } from '@/utils/config';
import { AllStageEnum } from '@/components/filters/sections/lead-stage-filter';
import { StageEnum } from '@/pages/crm/lead-stages/types';
import { DataTableExport } from '@/pages/admin/property-data/data-table-export';
import { DataTableViewOptions } from '@/pages/admin/property-data/data-table-view-options';
import { columns } from '@/pages/admin/property-data/columns';
import { ReactTable } from '@/components/table/react-table';

export type LeaderCompetitiveness = 0 | 1 | 2 | 3 | 4 | -1;
export type CallableOwners = 0 | 1 | 2;
export type EmailableOwners = 0 | 1 | 2;
export type MortgageType =
  | 'Conventional'
  | 'Federal Housing Administration'
  | 'Veterans Affairs';

export type DncStatus = 'DNC' | 'OPEN';

export interface MapProperty {
  id: number;
  quartile: number;
  state: string;
  lenderCompetitivenessRanking: LeaderCompetitiveness;
  originalMortgageType: MortgageType;
  longitude: number;
  latitude: number;
  callableOwners: CallableOwners;
  emailableOwners: EmailableOwners;
  originalMortgageInterestRate: number | string | null;
  originalMortgageAmount: number | string | null;
  recordDateLastArmsLengthSale: string | null;
  yearBuilt: number | null;
  doNotMail: boolean;
  salesPipelineId?: number;
}

export interface MapPropertyWithStage extends MapProperty {
  stage: AllStageEnum;
  assignedUserId?: number;
}

export interface AtomData {
  id: number;
  attomIdentifier: number;
}

export interface CommonProperty {
  firstCurrentOwnerPhoneNumber?: string | null;
  firstCurrentOwnerPhoneNumber2?: string | null;
  firstCurrentOwnerEmail?: string | null;
  secondCurrentOwnerPhoneNumber?: string | null;
  secondCurrentOwnerPhoneNumber2?: string | null;
  secondCurrentOwnerEmail?: string | null;
  situsFullAddress?: string | null;
  originalMortgageInterestRate?: number | string | null;
  originalMortgageAmount?: number | string | null;
  originalMortgageType?: MortgageType | null;
  originalMortgageLender?: string | null;
}

export interface ManualProperty extends CommonProperty {
  firstCurrentOwnerFullName?: string | null;
  secondCurrentOwnerFullName?: string | null;
}

export interface Property extends MapProperty {
  apn: string;
  fips: number;
  county: string;
  originalMortgageLender: string;
  situsFullAddress: string;
  propertySalePrice: string;
  firstCurrentOwnerFirstName: string | null;
  firstCurrentOwnerLastName: string | null;
  firstCurrentOwnerPhoneNumber?: string | null;
  firstCurrentOwnerPhoneNumber2?: string | null;
  firstCurrentOwnerEmail: string | null;
  firstCurrentOwnerPhoneIsConnected: boolean;
  firstOwnerDncConnectionStatus: string | null;
  firstCurrentOwnerPhoneType: string;
  firstOwnerDncIsCell: boolean | null;
  firstOwnerDncDncStatus: null | DncStatus;
  firstOwnerDncDncStatus2: null | DncStatus;
  secondCurrentOwnerFirstName: string | null;
  secondCurrentOwnerLastName: string | null;
  secondCurrentOwnerPhoneNumber?: string | null;
  secondCurrentOwnerPhoneNumber2?: string | null;
  secondCurrentOwnerEmail: string | null;
  secondCurrentOwnerPhoneIsConnected: boolean;
  secondOwnerDncConnectionStatus: string | null;
  secondCurrentOwnerPhoneType: string;
  secondOwnerDncIsCell: boolean | null;
  secondOwnerDncDncStatus: null | DncStatus;
  secondOwnerDncDncStatus2: null | DncStatus;
  bedrooms: number | null;
  bathrooms: number | null;
  homeSqft: number | null;
  atomData: AtomData | null;
  situsStreetAddress?: string | null;
  situsCity?: string | null;
}

export interface PropertyExtension extends Property {
  notes?: string;
}

export interface MapPropertyListResponse {
  count: number;
  data: MapProperty[];
}

export interface PropertyListResponse {
  count: number;
  data: Property[];
}

export interface SimpleSalesPipeline {
  id: number;
  stage: StageEnum;
  notes?: string;
  lostReasonDescription?: string;
  assignedUserId?: number;
}

export const PropertyTable = () => {
  const { getRequest } = useApi();
  const { isPending, error, data } = useQuery<PropertyListResponse>({
    queryKey: ['propertiesDataAll'],
    queryFn: () =>
      getRequest(`/api/properties?limit=${isLocal ? 200 : 100000}`),
  });
  const { data: salesPipelines } = useQuery<
    Record<number, SimpleSalesPipeline>
  >({
    queryKey: ['salesPipelineDataAll'],
    queryFn: () => getRequest('/api/sales_pipelines/all'),
  });

  const [properties, setProperties] = useState<PropertyExtension[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  useEffect(() => {
    if (data && salesPipelines) {
      setProperties(
        data.data.map((property) => {
          property.firstCurrentOwnerEmail;
          const salesPipeline = salesPipelines[property.id];
          return {
            ...property,
            ...(salesPipeline ?? {}),
          };
        })
      );
    }
  }, [data, salesPipelines]);

  const table = useReactTable<Property>({
    data: properties,
    columns,
    state: {
      sorting,
      columnVisibility,
      pagination,
    },
    enableRowSelection: true,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  if (isPending) {
    return <Loader />;
  }

  return (
    <div className="space-y-4 mt-6">
      <div className="flex justify-between items-center gap-2 w-full ">
        <div></div>
        <div className="flex space-x-3">
          <DataTableExport table={table} />
          <DataTableViewOptions table={table} />
        </div>
      </div>
      <div>
        {isPending && <Loader />}
        {properties && <ReactTable table={table} showSource />}
      </div>
    </div>
  );
};
