import { SalesPipeline } from '@/pages/crm/lead-stages/types';
import { MORTGAGE_TYPE_MAP } from '@/components/crm/board/board-column';

export const searchSalesPipeline = (
  searchString: string,
  data: SalesPipeline[]
): SalesPipeline[] => {
  if (searchString.trim() === '') {
    return data;
  }

  const searchWords = searchString.toLowerCase().split(' ');

  return data.filter((salesPipeline) => {
    return searchWords.every((word) => {
      const { property } = salesPipeline;

      if (!property) {
        return false;
      }

      return (
        (MORTGAGE_TYPE_MAP[property.originalMortgageType] ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.situsFullAddress ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.firstCurrentOwnerFirstName ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.firstCurrentOwnerLastName ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.firstCurrentOwnerPhoneNumber ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.firstCurrentOwnerPhoneNumber2 ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.firstCurrentOwnerEmail ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.secondCurrentOwnerFirstName ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.secondCurrentOwnerLastName ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.secondCurrentOwnerPhoneNumber ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.secondCurrentOwnerPhoneNumber2 ?? '')
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (property.secondCurrentOwnerEmail ?? '')
          .toLowerCase()
          .includes(word.toLowerCase())
      );
    });
  });
};
