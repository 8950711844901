import {
  MapProperty,
  Property,
} from '@/pages/admin/property-data/property-table';
import { RANKING_OPTIONS } from '@/components/filters/sections/lender-ranking-filter';
import { Item } from '@/components/map/property-details/item';
import { Skeleton } from '@/components/ui/skeleton';
import { AddressIcon } from '@/components/icons/address-icon';
import { formatRoundedMoney } from '@/utils';
import { BalanceIcon, BankIcon } from '@/components/icons';
import { MORTGAGE_TYPE_MAP } from '@/components/crm/board/board-column';
import { formatDateVerbose } from '@/utils/date';
import { SalesPipeline } from '@/pages/crm/lead-stages/types';
import { TagIcon } from '@/components/map/property-details/icons/tag-icon';
import { DoNotMailIcon } from '@/components/map/property-details/icons/do-not-mail-icon';
import { BuildYearIcon } from '@/components/map/property-details/icons/build-year-icon';
import { RankIcon } from '@/components/map/property-details/icons/rank-icon';
import { InterestRateIcon } from '@/components/map/property-details/icons/interest-rate-icon';

interface GeneralDetailsProps {
  mapProperty?: MapProperty;
  property?: Property;
  salesPipeline?: SalesPipeline;
}

export const GeneralDetails = ({
  mapProperty,
  property,
  salesPipeline,
}: GeneralDetailsProps) => {
  const commonProperty =
    property ??
    salesPipeline?.property ??
    salesPipeline?.manualProperty ??
    undefined;
  const loading = commonProperty === undefined;

  return (
    <div className="w-[320px]">
      <div>
        <h2 className="text-dark-dark text-heading-05 my-4">PROPERTY</h2>

        <div className="flex justify-between items-center px-3 py-1.5 bg-white space-x-3">
          <div className="w-6 text-dark-dark">
            <AddressIcon />
          </div>
          <div className="text-dark-tint text-paragraph-sm">
            {commonProperty?.situsFullAddress ?? (
              <Skeleton className="h-7 w-full" />
            )}
          </div>
        </div>

        <ul>
          <Item
            label="IR"
            value={
              commonProperty?.originalMortgageInterestRate
                ? `${parseFloat(commonProperty?.originalMortgageInterestRate?.toString() ?? '0')}%`
                : 'N/A'
            }
            icon={<InterestRateIcon />}
            loading={loading}
          />
          <Item
            label="Mortgage Type"
            value={
              MORTGAGE_TYPE_MAP[
                commonProperty?.originalMortgageType as string
              ] ?? commonProperty?.originalMortgageType
            }
            loading={loading}
          />
          {!salesPipeline?.manualProperty && (
            <Item
              icon={<RankIcon />}
              label="Lender Competitiveness"
              value={
                RANKING_OPTIONS.find(
                  (option) =>
                    option.value ===
                    (property?.lenderCompetitivenessRanking ??
                      mapProperty?.lenderCompetitivenessRanking)
                )?.label ?? 'Unassigned'
              }
              loading={loading}
            />
          )}
          <Item
            icon={<BankIcon />}
            label="Lender"
            value={commonProperty?.originalMortgageLender ?? ''}
            loading={loading}
          />
          <Item
            label="Est. Mortgage Balance"
            value={formatRoundedMoney(
              parseInt(
                commonProperty?.originalMortgageAmount?.toString() ?? '0'
              )
            )}
            icon={<BalanceIcon />}
          />
          {!salesPipeline?.manualProperty && (
            <>
              <Item
                label="Last Arms-Length Sale Date"
                icon={<TagIcon />}
                value={formatDateVerbose(
                  property?.recordDateLastArmsLengthSale
                )}
              />
              <Item
                label="Build Year"
                icon={<BuildYearIcon />}
                value={property?.yearBuilt ?? ''}
              />
              <Item
                label="Do Not Mail Registered"
                icon={<DoNotMailIcon />}
                value={property?.doNotMail ? 'Yes' : 'No'}
              />
            </>
          )}
        </ul>
      </div>
    </div>
  );
};
