import { FilterContainer } from '@/components/filters/sections/filter-container';
import { Slider } from '@/components/ui/slider';
import { defaultBuildYearRange } from '@/components/filters/sections/constants';

export interface Range {
  from: number;
  to: number;
}

export interface BuildYearFilterProps {
  selectedBuildYearRange: Range;
  onSelectBuildYearRange: (selectedBuildYearRange: Range) => void;
}

export const BuildYearFilter = ({
  selectedBuildYearRange,
  onSelectBuildYearRange,
}: BuildYearFilterProps) => {
  return (
    <FilterContainer
      value="state"
      title={
        <div className="text-heading-05 text-dark-dark">Build Year Range</div>
      }
    >
      <div className="flex items-center justify-center space-x-2 px-6">
        <div className="text-eyebrow-md text-navy-tint border-[1px] px-auto w-[90px] border-navy-tint rounded py-1.5 flex items-center justify-center">
          {selectedBuildYearRange.from}
        </div>
        <Slider
          defaultValue={[
            selectedBuildYearRange.from,
            selectedBuildYearRange.to,
          ]}
          min={defaultBuildYearRange.from}
          max={defaultBuildYearRange.to}
          step={1}
          onValueChange={(values) => {
            onSelectBuildYearRange({ from: values[0], to: values[1] });
          }}
        />
        <div className="text-eyebrow-md text-navy-tint border-[1px] px-auto w-[90px] border-navy-tint rounded py-1.5 flex items-center justify-center">
          {selectedBuildYearRange.to}
        </div>
      </div>
    </FilterContainer>
  );
};
