import { CalendarIcon } from 'lucide-react';
import { format } from 'date-fns';
import { DateRange } from 'react-day-picker';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';

export interface DateRangeSelectorProps {
  selectedDateRange: DateRange;
  onSelectDateRange: (selectedDateRange: DateRange) => void;
}

export const DateRangeSelector = ({
  selectedDateRange,
  onSelectDateRange,
}: DateRangeSelectorProps) => {
  return (
    <div className="flex items-center justify-start space-x-2 px-6">
      <div className="grid gap-2">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              id="date"
              variant={'outline'}
              className={cn(
                'justify-start text-left font-normal w-full text-eyebrow-md',
                !selectedDateRange && 'text-muted-foreground'
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {selectedDateRange?.from ? (
                selectedDateRange.to ? (
                  <>
                    {format(selectedDateRange.from, 'LLL dd, y')} -{' '}
                    {format(selectedDateRange.to, 'LLL dd, y')}
                  </>
                ) : (
                  format(selectedDateRange.from, 'LLL dd, y')
                )
              ) : (
                <span>Pick a date</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              initialFocus
              mode="range"
              captionLayout="dropdown-buttons"
              fromDate={new Date('2010-01-01')}
              toDate={new Date()}
              defaultMonth={selectedDateRange?.from}
              selected={selectedDateRange}
              onSelect={(range: DateRange | undefined) => {
                if (range) {
                  onSelectDateRange(range);
                }
              }}
              numberOfMonths={2}
            />
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};
