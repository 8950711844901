import { useEffect, useState } from 'react';
import { useNotificationContext } from '@/context';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { BellIcon } from '@/components/icons';
import { TaskItem } from '@/components/layouts/top-navigation/task-item';

export const Notification = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { refetchNotifications, notifications } = useNotificationContext();
  const itemsCount = notifications.due.length;

  useEffect(() => {
    refetchNotifications();

    const intervalId = setInterval(() => {
      refetchNotifications();
    }, 600000); // 10 minutes in milliseconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [refetchNotifications]);

  return (
    <Popover onOpenChange={(open) => setIsPopoverOpen(open)}>
      <PopoverTrigger asChild>
        <button className="text-dark-dark">
          <div className="relative">
            <BellIcon active={isPopoverOpen} />
            {itemsCount > 0 && (
              <div className="px-1 bg-red-red rounded-full text-center text-white text-sm absolute -top-3 -end-2">
                {itemsCount}
                <div className="absolute top-0 start-0 rounded-full -z-10 animate-ping bg-teal-200 w-full h-full"></div>
              </div>
            )}
          </div>
          <span className="sr-only">Toggle navigation menu</span>
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-80 mt-2 mr-2 overflow-y-auto">
        <div
          className="grid gap-4"
          style={{ maxHeight: 'calc(100vh - 110px)' }}
        >
          <div className="space-y-2">
            <h4 className="text-heading-04">Notifications</h4>
          </div>
          <div className="grid gap-2">
            {itemsCount === 0 ? (
              <div className="text-navy-navy">All Clear</div>
            ) : (
              notifications.due.map((item) => (
                <TaskItem key={item.id} item={item} />
              ))
            )}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
