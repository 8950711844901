import { z } from 'zod';
import { stateAbbreviations } from '@/utils';

export type MortgageType = 'FHA' | 'VA' | 'CNV';
export const AllMortgageTypes = ['FHA', 'VA', 'CNV'];

const isoDateString = z.string().refine((val) => !isNaN(Date.parse(val)), {
  message: 'Invalid date format',
});

export const formSchema = z.object({
  states: z
    .array(z.enum(stateAbbreviations as [string, ...string[]]))
    .min(1, 'At least one state must be selected'),
  transactionType: z.number().min(1, 'Transaction type is required'),
  sellerCarryback: z.number().min(0, 'Seller carryback type is required'),
  mortgageType: z
    .array(z.enum(AllMortgageTypes as [string, ...string[]]))
    .min(1, 'At least one mortgage type must be selected'),
  mortgageAmount: z
    .object({
      from: z.number().min(0, 'From amount must be at least 0'),
      to: z.number().min(0, 'To amount must be at least 0'),
    })
    .refine((data) => data.to > data.from, {
      message: 'To amount must be greater than from amount',
      path: ['to'],
    }),
  interestRate: z
    .object({
      from: z.number().min(0).max(20, 'From rate must be at most 20'),
      to: z.number().min(0).max(20, 'To rate must be at most 20'),
    })
    .refine((data) => data.to >= data.from, {
      message: 'To rate must be greater than or equal to from rate',
      path: ['to'],
    }),
  lastSaleDate: z
    .object({
      from: isoDateString,
      to: isoDateString,
    })
    .refine((data) => new Date(data.to) >= new Date(data.from), {
      message: 'To date must be greater than or equal to from date',
      path: ['to'],
    }),
  ownershipType: z.number().min(1, 'Ownership type is required'),
});

export type ManualDataImportFormData = z.infer<typeof formSchema>;
