import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useEffect, useState } from 'react';
import { Input } from '@/components/ui/input';
import { ManualProperty } from '@/pages/admin/property-data/property-table';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { MORTGAGE_TYPE_MAP } from '@/components/crm/board/board-column';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';

interface NewLeadDialogProps {
  open: boolean;

  onClose?(): void;

  createNewLead?(newLead: ManualProperty): Promise<void>;

  isCreating?: boolean;
}

interface UIState {
  tabValue: string;
}

const nameRegex = /^[a-zA-Z\s]+$/;
const phoneRegex = /^[\d\s()-]+$/;

const formSchema = z.object({
  firstCurrentOwnerFullName: z
    .string()
    .min(1, { message: 'Full name is required' })
    .max(30, { message: 'Full name must be 30 characters or less' })
    .regex(nameRegex, {
      message: 'Full name must contain only letters and spaces',
    }),
  firstCurrentOwnerPhoneNumber: z
    .string()
    .regex(phoneRegex, { message: 'Invalid phone number format.' })
    .max(30, { message: 'Phone number must be 30 characters or less' })
    .optional(),
  firstCurrentOwnerPhoneNumber2: z
    .string()
    .regex(phoneRegex, { message: 'Invalid phone number format.' })
    .max(30, { message: 'Phone number must be 30 characters or less' })
    .optional(),
  firstCurrentOwnerEmail: z
    .string()
    .email({ message: 'Invalid email format' })
    .max(100, { message: 'Email must be 100 characters or less' })
    .optional(),
  secondCurrentOwnerFullName: z
    .string()
    .max(30, { message: 'Full name must be 30 characters or less' })
    .regex(nameRegex, {
      message: 'Full name must contain only letters and spaces',
    })
    .optional(),
  secondCurrentOwnerPhoneNumber: z
    .string()
    .regex(phoneRegex, { message: 'Invalid phone number format.' })
    .max(30, { message: 'Phone number must be 30 characters or less' })
    .optional(),
  secondCurrentOwnerPhoneNumber2: z
    .string()
    .regex(phoneRegex, { message: 'Invalid phone number format.' })
    .max(30, { message: 'Phone number must be 30 characters or less' })
    .optional(),
  secondCurrentOwnerEmail: z
    .string()
    .email({ message: 'Invalid email format' })
    .max(100, { message: 'Email must be 100 characters or less' })
    .optional(),
  situsFullAddress: z
    .string()
    .max(200, { message: 'Address must be 200 characters or less' })
    .optional(),
  originalMortgageInterestRate: z
    .string()
    .max(10, { message: 'Interest rate must be 10 characters or less' })
    .optional(),
  originalMortgageAmount: z.string().optional(),
  originalMortgageType: z
    .enum([
      'Conventional',
      'Federal Housing Administration',
      'Veterans Affairs',
    ])
    .optional(),
  originalMortgageLender: z
    .string()
    .max(200, { message: 'Lender must be 200 characters or less' })
    .optional(),
});

export const NewLeadDialog = ({
  open,
  onClose,
  createNewLead,
  isCreating,
}: NewLeadDialogProps) => {
  const [uiState, setUIState] = useState<UIState>({ tabValue: 'firstOwner' });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstCurrentOwnerFullName: '',
    },
  });

  useEffect(() => {
    if (open) {
      form.reset();
    }
  }, [form, open]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    await createNewLead?.(values);
  };

  return (
    <Dialog open={open} onOpenChange={() => onClose?.()}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>New Lead</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            className="w-full space-y-5"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <Tabs value={uiState.tabValue}>
              <div className="flex items-center">
                <TabsList>
                  <TabsTrigger
                    value="firstOwner"
                    onClick={() =>
                      setUIState((prevState) => ({
                        ...prevState,
                        tabValue: 'firstOwner',
                      }))
                    }
                  >
                    First Owner
                  </TabsTrigger>
                  <TabsTrigger
                    value="secondOwner"
                    onClick={() =>
                      setUIState((prevState) => ({
                        ...prevState,
                        tabValue: 'secondOwner',
                      }))
                    }
                  >
                    Second Owner
                  </TabsTrigger>
                </TabsList>
                <div className="ml-auto flex items-center gap-2"></div>
              </div>
              <TabsContent value="firstOwner">
                <div className="grid grid-cols-2 gap-3">
                  <div className="space-y-1 col-span-2">
                    <FormField
                      control={form.control}
                      name="firstCurrentOwnerFullName"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Full name</FormLabel>
                          <FormControl>
                            <Input {...field} autoFocus maxLength={30} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="space-y-1">
                    <FormField
                      control={form.control}
                      name="firstCurrentOwnerPhoneNumber"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Phone number</FormLabel>
                          <FormControl>
                            <Input {...field} maxLength={30} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="space-y-1">
                    <FormField
                      control={form.control}
                      name="firstCurrentOwnerEmail"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                            <Input {...field} maxLength={100} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </TabsContent>
              <TabsContent value="secondOwner">
                <div className="grid grid-cols-2 gap-3">
                  <div className="space-y-1 col-span-2">
                    <FormField
                      control={form.control}
                      name="secondCurrentOwnerFullName"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Full name</FormLabel>
                          <FormControl>
                            <Input {...field} autoFocus maxLength={30} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="space-y-1">
                    <FormField
                      control={form.control}
                      name="secondCurrentOwnerPhoneNumber"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Phone number</FormLabel>
                          <FormControl>
                            <Input {...field} maxLength={30} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="space-y-1">
                    <FormField
                      control={form.control}
                      name="secondCurrentOwnerEmail"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                            <Input {...field} maxLength={100} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </TabsContent>

              <div className="mt-6 space-y-3">
                <div className="text-label-md font-bold">Property Details</div>
                <div className="space-y-1">
                  <FormField
                    control={form.control}
                    name="situsFullAddress"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Address</FormLabel>
                        <FormControl>
                          <Input {...field} maxLength={200} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <div className="space-y-1">
                    <FormField
                      control={form.control}
                      name="originalMortgageInterestRate"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>IR (%)</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              maxLength={10}
                              type="number"
                              min={0}
                              max={20}
                              step={0.01}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="space-y-1">
                    <FormField
                      control={form.control}
                      name="originalMortgageAmount"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Mortgage Amount</FormLabel>
                          <FormControl>
                            <Input {...field} maxLength={50} type="number" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="space-y-1">
                    <FormField
                      control={form.control}
                      name="originalMortgageType"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Mortgage Type</FormLabel>
                          <FormControl>
                            <Select
                              value={field.value ?? undefined}
                              onValueChange={(value) => field.onChange(value)}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select a mortgage type" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  {Object.keys(MORTGAGE_TYPE_MAP).map((key) => (
                                    <SelectItem key={key} value={key}>
                                      {MORTGAGE_TYPE_MAP[key]}
                                    </SelectItem>
                                  ))}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="space-y-1">
                    <FormField
                      control={form.control}
                      name="originalMortgageLender"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Lender</FormLabel>
                          <FormControl>
                            <Input {...field} maxLength={200} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </div>
            </Tabs>
            <DialogFooter>
              <Button
                type="submit"
                disabled={isCreating}
                onClick={async () => {
                  setUIState((prevState) => ({
                    ...prevState,
                    tabValue: 'firstOwner',
                  }));
                }}
              >
                {isCreating ? 'Creating' : 'Create'}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
