import { AlertCircle } from 'lucide-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CreateUserButton } from './create-user-button';
import { EditUserDialog } from './edit-user-dialog';
import React, { useEffect, useState } from 'react';
import { DeleteUserAlertDialog } from '@/pages/admin/user-list/delete-user-alert-dialog';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Loader } from '@/components';
import { useApi } from '@/hooks/use-api';
import { Sidebar } from '@/pages/settings/common/sidebar';
import { EditIcon, ReleaseIcon } from '@/components/icons';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { DropdownButtonIcon } from '@/components/map/property-details/icons/dropdown-button-icon';
import { useToast } from '@/components/ui/use-toast';
import { useAuth } from '@/context';
import {
  ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/table/data-table-column-header';
import { ReactTable } from '@/components/table/react-table';
import { Checkbox } from '@/components/ui/checkbox';

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  isActive: boolean;
  phone?: string;
  userType: 'LO' | 'AE';
  assignedLeadsCap: number;
  leadsCurrentlyAssigned: number;
  isSuperuser: boolean;
  email: string;
}

export interface UserListResponse {
  count: number;
  data: User[];
}

interface UpdateUserStatus {
  id: number;
  isSuperuser?: boolean;
  isActive?: boolean;
  userType?: string;
}

const userTypeMapping: Record<string, string> = {
  LO: 'Loan Officer',
  AE: 'Account Executive',
};

export const UserList = () => {
  const { getRequest, patchRequest, postRequest } = useApi();
  const [openEditUserDialog, setOpenEditUserDialog] = React.useState(false);
  const { toast } = useToast();

  const [editUserForm, setEditUserForm] = React.useState<User>({
    id: 0,
    firstName: '',
    lastName: '',
    phone: '',
    isActive: false,
    isSuperuser: false,
    assignedLeadsCap: 300,
    leadsCurrentlyAssigned: 0,
    userType: 'LO',
    email: '',
  });
  const [selectedRows, setSelectedRows] = useState<User[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [openDeleteConfirmAlert, setOpenDeleteConfirmAlert] =
    React.useState(false);
  const { isPending, error, data, refetch } = useQuery<UserListResponse>({
    queryKey: ['usersData'],
    queryFn: () => getRequest('/api/users'),
  });
  const { isPending: isReleasing, mutateAsync } = useMutation({
    mutationFn: () =>
      postRequest('/api/users/release_leads', {
        userIds: selectedRows.map((r) => r.id),
      }),
    onSuccess: (response) => {
      setSelectedRows([]);
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          selectedRows.some((selectedUser) => selectedUser.id === user.id)
            ? { ...user, leadsCurrentlyAssigned: 0 }
            : user
        )
      );
      toast({
        variant: 'success',
        description: `Selected users leads have been released to the market. Released leads: ${response.releasedLeads}`,
        title: 'Leads Released',
      });
    },
  });
  const [users, setUsers] = useState<User[]>([]);
  const { userDetails } = useAuth();

  useEffect(() => {
    if (data) {
      setUsers(data.data);
    }
  }, [data]);

  const { mutateAsync: updateUser } = useMutation({
    mutationFn: (updatedUser: UpdateUserStatus) => {
      const { id, ...props } = updatedUser;
      return patchRequest(`/api/users/${id}`, props);
    },
    onSuccess: (user: User) => {
      setUsers((prevUsers) =>
        prevUsers.map((u) =>
          u.id === user.id
            ? {
                ...u,
                ...user,
                leadsCurrentlyAssigned: u.leadsCurrentlyAssigned,
              }
            : u
        )
      );
      toast({
        variant: 'success',
        description: `${user.firstName} has been updated successfully.`,
        title: 'User Updated',
      });
    },
  });

  const columns: ColumnDef<User>[] = [
    {
      id: 'select',
      header: () => (
        <Checkbox
          checked={selectedRows.length === users.length}
          onClick={async () => {
            const isAllPageRowsSelected = selectedRows.length === users.length;
            if (isAllPageRowsSelected) {
              setSelectedRows([]);
            } else {
              setSelectedRows(users);
            }
          }}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={selectedRows.map((u) => u.id).includes(row.original.id)}
          onClick={() => {
            const isSelected = selectedRows
              .map((u) => u.id)
              .includes(row.original.id);
            if (isSelected) {
              setSelectedRows((prev) =>
                prev.filter((u) => u.id !== row.original.id)
              );
            } else {
              setSelectedRows((prev) => [...prev, row.original]);
            }
          }}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'id',
      meta: {
        title: 'Edit',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        const user = row.original as User;
        return (
          <button
            className="w-6 h-6"
            onClick={() => {
              setEditUserForm(user);
              setOpenEditUserDialog(true);
            }}
          >
            <EditIcon />
          </button>
        );
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'firstName',
      meta: {
        title: 'First Name',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => <div>{row.getValue('firstName')}</div>,
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'lastName',
      meta: {
        title: 'Last Name',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => <div>{row.getValue('lastName')}</div>,
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'email',
      meta: {
        title: 'Email',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => <div>{row.getValue('email')}</div>,
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'phone',
      meta: {
        title: 'Phone Number',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => <div>{row.getValue('phone')}</div>,
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'assignedLeadsCap',
      meta: {
        title: 'Assigned Leads Cap',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        const user = row.original as User;
        return (
          <div>{user.isSuperuser ? 'No Limit' : user.assignedLeadsCap}</div>
        );
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'leadsCurrentlyAssigned',
      meta: {
        title: 'Leads Currently Assigned',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => <div>{row.getValue('leadsCurrentlyAssigned')}</div>,
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'isSuperuser',
      meta: {
        title: 'User Type',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        const user = row.original as User;
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant={user.isSuperuser ? 'blue' : 'yellow'}>
                <span>
                  {user.isSuperuser ? 'Admin' : userTypeMapping[user.userType]}
                </span>
                <span>
                  <DropdownButtonIcon />
                </span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="start"
              className={`min-w-48 ${user.isSuperuser ? 'bg-blue-tint text-blue-shade' : 'bg-yellow-tint text-yellow-shade'}`}
            >
              <DropdownMenuGroup>
                {Object.keys(userTypeMapping).map((key) => (
                  <DropdownMenuCheckboxItem
                    checked={!user.isSuperuser && user.userType === key}
                    className={
                      user.isSuperuser
                        ? 'focus:text-blue-tint focus:bg-blue-blue'
                        : 'focus:text-yellow-tint focus:bg-yellow-yellow'
                    }
                    onClick={async () => {
                      if (
                        user.isSuperuser ||
                        (!user.isSuperuser && user.userType !== key)
                      ) {
                        if (user.email === userDetails?.email) {
                          toast({
                            variant: 'destructive',
                            description: 'You cannot change your own role',
                            title: 'Error',
                          });
                          return;
                        }
                        await updateUser({
                          id: user.id,
                          isSuperuser: false,
                          userType: key,
                        });
                      }
                    }}
                  >
                    {userTypeMapping[key]}
                  </DropdownMenuCheckboxItem>
                ))}

                <DropdownMenuCheckboxItem
                  checked={user.isSuperuser}
                  className={
                    user.isSuperuser
                      ? 'focus:text-blue-tint focus:bg-blue-blue'
                      : 'focus:text-yellow-tint focus:bg-yellow-yellow'
                  }
                  onClick={async () => {
                    if (!user.isSuperuser) {
                      await updateUser({
                        id: user.id,
                        isSuperuser: true,
                        userType: 'LO',
                      });
                    }
                  }}
                >
                  Admin
                </DropdownMenuCheckboxItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
      enableSorting: true,
      enableHiding: false,
    },
    {
      accessorKey: 'isActive',
      meta: {
        title: 'Status',
      },
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => {
        const user = row.original as User;
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant={user.isActive ? 'green' : 'red'}>
                <span>{user.isActive ? 'Active' : 'Inactive'}</span>
                <span>
                  <DropdownButtonIcon />
                </span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="start"
              className={`min-w-48 ${user.isActive ? 'bg-green-tint text-green-shade' : 'bg-red-tint text-red-shade'}`}
            >
              <DropdownMenuGroup>
                <DropdownMenuCheckboxItem
                  checked={user.isActive}
                  className={
                    user.isActive
                      ? 'focus:text-blue-tint focus:bg-blue-blue'
                      : 'focus:text-red-tint focus:bg-red-red'
                  }
                  onClick={async () => {
                    if (!user.isActive) {
                      await updateUser({
                        id: user.id,
                        isActive: true,
                      });
                    }
                  }}
                >
                  Active
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  checked={!user.isActive}
                  className={
                    user.isActive
                      ? 'focus:text-blue-tint focus:bg-blue-blue'
                      : 'focus:text-red-tint focus:bg-red-red'
                  }
                  onClick={async () => {
                    if (user.isActive) {
                      if (user.email === userDetails?.email) {
                        toast({
                          variant: 'destructive',
                          description: 'You cannot deactivate yourself',
                          title: 'Error',
                        });
                        return;
                      }
                      await updateUser({
                        id: user.id,
                        isActive: false,
                      });
                    }
                  }}
                >
                  Inactive
                </DropdownMenuCheckboxItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
      enableSorting: true,
      enableHiding: false,
    },
  ];

  const table = useReactTable<User>({
    data: users ?? [],
    columns,
    state: {
      sorting,
      columnVisibility,
      pagination,
    },
    enableRowSelection: true,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );
  }

  return (
    <Sidebar currentPage="user-management">
      <div className="flex flex-col space-y-4">
        <div>
          <div className="text-heading-01">User Management</div>
        </div>
        <div className="ml-auto gap-1 flex space-x-3">
          <CreateUserButton onUserCreated={() => refetch()} />
          <Button
            variant="default"
            disabled={selectedRows.length === 0 || isReleasing}
            onClick={() => mutateAsync()}
          >
            <span>Release User Leads to Market</span>
            <ReleaseIcon className="h-4 w-4" />
          </Button>
        </div>

        <div>
          {isPending && <Loader />}
          {users && <ReactTable table={table} />}
        </div>
        <EditUserDialog
          onUserUpdated={() => refetch()}
          open={openEditUserDialog}
          onOpenChange={setOpenEditUserDialog}
          editUserForm={editUserForm}
          isLoggedInUser={editUserForm.email === userDetails?.email}
        />
        <DeleteUserAlertDialog
          onUserDeleted={() => refetch()}
          open={openDeleteConfirmAlert}
          onOpenChange={setOpenDeleteConfirmAlert}
          deleteUserForm={editUserForm}
        />
      </div>
    </Sidebar>
  );
};
