import { useCallback, useState, useEffect, useRef } from 'react';
import { useApi } from '@/hooks/use-api';
import { ManualDataImportFormData } from '@/pages/data-pipeline/manual-data-import/types';

interface Task {
  taskId?: string;
  status?: 'PENDING' | 'SUCCESS' | 'FAILED';
  result?: { count: number };
}

export const useManualDataImport = () => {
  const [task, setTask] = useState<Task>({});

  const { postRequest, getRequest } = useApi();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const pollStatus = useCallback(
    (taskId: string) => {
      intervalRef.current = setInterval(async () => {
        try {
          const data = (await getRequest(
            `/api/data_pipeline/job-status/${taskId}`
          )) as {
            status: 'PENDING' | 'SUCCESS' | 'FAILED';
            result?: { count: number };
          };

          if (data.status === 'SUCCESS' || data.status === 'FAILED') {
            clearInterval(intervalRef.current!);
            setTask((prev) => ({ ...prev, ...data }));
          }
        } catch (error) {
          setTask((prev) => ({ ...prev, status: 'FAILED' }));
          console.error('Error fetching task status:', error);
          clearInterval(intervalRef.current!);
        }
      }, 1000);
    },
    [getRequest]
  );

  const getCount = useCallback(
    async (value: ManualDataImportFormData) => {
      try {
        const data = (await postRequest('/api/data_pipeline/get_count', {
          ...value,
          mortgageAmount: {
            fromAmount: value.mortgageAmount.from,
            toAmount: value.mortgageAmount.to,
          },
          interestRate: {
            fromRate: value.interestRate.from,
            toRate: value.interestRate.to,
          },
          last_sale_date: {
            fromDate: value.lastSaleDate.from,
            toDate: value.lastSaleDate.to,
          },
        })) as {
          taskId: string;
        };
        setTask({ taskId: data.taskId, status: 'PENDING' });
        pollStatus(data.taskId);
      } catch (error) {
        console.error('Error fetching count:', error);
      }
    },
    [pollStatus, postRequest]
  );

  const clearCount = useCallback(() => {
    setTask({});
  }, []);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return {
    task,
    getCount,
    clearCount,
  };
};
