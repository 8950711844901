import { BoardColumn } from './board-column';
import { SalesPipeline, StageEnum } from '@/pages/crm/lead-stages/types';
import { useAuth } from '@/context';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useCallback, useState } from 'react';
import {
  allStages,
  BoardSortState,
  fetchBoardSortState,
  SortOrder,
  storeBoardSortState,
} from '@/components/crm/board/utils';
import { EmailTemplateDialog } from '@/pages/crm/sales-funnel/email-template-dialog';
import { EmailTemplate } from '@/pages/admin/email-templates/types';

interface BoardProps {
  salesPipelines: SalesPipeline[];
  emailTemplates: EmailTemplate[];
  openEmailClient(salesPipeline: SalesPipeline): Promise<void>;
  onShowDetails?(salesPipeline: SalesPipeline): void;
  moveCard?(salesPipeline: SalesPipeline, stage: StageEnum): Promise<void>;
}

interface EmailDialogState {
  open: boolean;
  salesPipeline?: SalesPipeline;
}

export const Board = ({
  salesPipelines,
  onShowDetails,
  moveCard,
  emailTemplates,
  openEmailClient,
}: BoardProps) => {
  const { userDetails } = useAuth();

  const [boardSortState, setBoardSortState] = useState<BoardSortState>(
    fetchBoardSortState()
  );

  const [emailDialogState, setEmailDialogState] = useState<EmailDialogState>({
    open: false,
    salesPipeline: undefined,
  });

  const updateBoardSortState = useCallback(
    (stage: StageEnum, sortOrder: SortOrder) => {
      setBoardSortState((prev) => {
        const newState = { ...prev, [stage]: sortOrder };
        storeBoardSortState(newState);
        return newState;
      });
    },
    []
  );

  const openEmailDialog = useCallback(
    async (salesPipeline: SalesPipeline) => {
      // setEmailDialogState({ open: true, salesPipeline });
      await openEmailClient(salesPipeline);
    },
    [openEmailClient]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="w-full" style={{ maxHeight: 'calc(100vh - 182px)' }}>
        <div className="flex items-start gap-2 w-full overflow-y-auto">
          {allStages.map((stage) => (
            <BoardColumn
              key={stage.toString()}
              stage={stage}
              salesPipelines={salesPipelines.filter(
                (salesPipeline) => salesPipeline.stage === stage
              )}
              onShowDetails={onShowDetails}
              moveCard={moveCard}
              isSuperuser={userDetails.isSuperuser}
              boardSortState={boardSortState}
              updateBoardSortState={updateBoardSortState}
              openEmailDialog={openEmailDialog}
            />
          ))}
        </div>

        <EmailTemplateDialog
          open={emailDialogState.open}
          onOpenChange={(open) =>
            setEmailDialogState({
              open,
              salesPipeline: emailDialogState.salesPipeline,
            })
          }
          salesPipeline={emailDialogState.salesPipeline!}
          emailTemplates={emailTemplates}
          userFullName={`${userDetails.firstName} ${userDetails.lastName}`}
        />
      </div>
    </DndProvider>
  );
};
