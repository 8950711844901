import { FilterContainer } from '@/components/filters/sections/filter-container';
import { BalanceSlider } from '@/components/filters/sections/balance-slider';

export interface Range {
  from: number;
  to: number;
}

export interface MortgageBalanceFilterProps {
  selectedMortgageBalanceRange: Range;
  onSelectMortgageBalanceRange: (selectedMortgageBalanceRange: Range) => void;
}

export const MortgageBalanceFilter = ({
  selectedMortgageBalanceRange,
  onSelectMortgageBalanceRange,
}: MortgageBalanceFilterProps) => {
  return (
    <FilterContainer
      value="state"
      title={
        <div className="text-heading-05 text-dark-dark">
          Est. Mortgage Balance
        </div>
      }
    >
      <BalanceSlider
        selectedBalanceRange={selectedMortgageBalanceRange}
        onSelectBalanceRange={onSelectMortgageBalanceRange}
      />
    </FilterContainer>
  );
};
