import { StageEnum } from '@/pages/crm/lead-stages/types';

interface BoardTitleProps {
  stage: StageEnum;
  cardCount: number;
}

export const titleMap: Record<StageEnum, string> = {
  assigned_lead: 'Assigned',
  follow_up: 'Follow-up',
  front_line_processing: 'Front Line Processing',
  refinancing: 'Refinancing',
  refinanced: 'Refinanced',
  lost: 'Lost',
};

const classNameMap = {
  assigned_lead: 'bg-dark-dark',
  follow_up: 'bg-[#215F99]',
  front_line_processing: 'bg-blue-shade',
  refinancing: 'bg-[#1A6B25]',
  refinanced: 'bg-green-shade',
  lost: 'bg-error-shade',
};

export const BoardTitle = ({ stage, cardCount }: BoardTitleProps) => {
  const title = titleMap[stage];
  const className = classNameMap[stage];

  return (
    <div
      className={`rounded-t-lg px-3.5 py-2.5 w-full bg-[#192a68] justify-between items-center flex text-white ${className}`}
    >
      <div className="text-heading-05">{title}</div>
      <div className="text-label-md">{cardCount}</div>
    </div>
  );
};
