import {
  MapProperty,
  Property,
} from '@/pages/admin/property-data/property-table';
import {
  SalesPipeline,
  UpdateSalesPipeline,
} from '@/pages/crm/lead-stages/types';

import { TopSection } from '@/components/map/property-details/top-section';
import { MainSection } from '@/components/map/property-details/main-section';
import { OwnerForm } from '@/components/map/property-details/types';

interface PropertyDetailsProps {
  mapProperty?: MapProperty;
  property?: Property;
  salesPipeline?: SalesPipeline;

  onOpenChange?(open: boolean): void;

  changeStage?(
    salesPipelineId: number,
    fromStage: string,
    toStage: string
  ): Promise<void>;

  closeAsLost?(
    salesPipelineId: number,
    fromStage: string,
    lostReasonCode: number,
    lostReasonDescription: string
  ): Promise<void>;

  reactivate?(salesPipelineId: number): Promise<void>;

  updateSalesPipeline?(
    salesPipelineId: number,
    data: UpdateSalesPipeline
  ): Promise<void>;

  updateSalesPipelineOwner?(params: {
    position: 1 | 2;
    ownerForm: OwnerForm;
  }): Promise<void>;
}

export const PropertyDetails = ({
  mapProperty,
  property,
  salesPipeline,
  changeStage,
  closeAsLost,
  reactivate,
  updateSalesPipeline,
  onOpenChange,
  updateSalesPipelineOwner,
}: PropertyDetailsProps) => {
  return (
    <div className="space-y-6">
      <TopSection
        mapProperty={mapProperty}
        property={property}
        salesPipeline={salesPipeline}
        changeStage={changeStage}
        closeAsLost={closeAsLost}
        reactivate={reactivate}
        updateSalesPipeline={updateSalesPipeline}
        onOpenChange={onOpenChange}
      />

      <MainSection
        updateSalesPipeline={updateSalesPipeline}
        updateSalesPipelineOwner={updateSalesPipelineOwner}
        property={property ?? salesPipeline?.property}
        salesPipeline={salesPipeline}
      />
    </div>
  );
};
