import { DownloadIcon } from 'lucide-react';
import { Table } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import { toISODate } from '@/utils/date';
import { exportCsv } from '@/utils/csv';

interface DataTableExportProps<TData> {
  table: Table<TData>;
}

export function DataTableExport<TData>({ table }: DataTableExportProps<TData>) {
  return (
    <Button
      variant="outline"
      size="sm"
      className="ml-auto hidden h-8 lg:flex"
      onClick={() => {
        const formattedDate = toISODate(new Date());
        exportCsv(`property-data_${formattedDate}`, table);
      }}
    >
      <DownloadIcon className="mr-2 h-4 w-4" />
      Download as CSV
    </Button>
  );
}
