import { EmailableOwners } from '@/pages/admin/property-data/property-table';
import { FilterContainer } from '@/components/filters/sections/filter-container';
import { CheckboxWithLabel } from '@/components/filters/sections/checkbox-with-label';

interface Option {
  value: EmailableOwners;
  label: string;
}

const OPTIONS: Option[] = [
  {
    value: 2,
    label: 'Two',
  },
  {
    value: 1,
    label: 'One',
  },
  {
    value: 0,
    label: 'Zero',
  },
];

export interface EmailableOwnersFilterProps {
  selectedEmailableOwners: EmailableOwners[];
  onSelectEmailableOwners: (selectedEmailableOwners: EmailableOwners[]) => void;
}

export const EmailableOwnersFilter = ({
  selectedEmailableOwners,
  onSelectEmailableOwners,
}: EmailableOwnersFilterProps) => {
  const handleEmailableOwnersChange = (emailableOwners: EmailableOwners) => {
    if (selectedEmailableOwners.includes(emailableOwners)) {
      const newEmailableOwners = selectedEmailableOwners.filter(
        (item) => item !== emailableOwners
      );
      if (newEmailableOwners.length > 0) {
        onSelectEmailableOwners(newEmailableOwners);
      }
    } else {
      onSelectEmailableOwners([...selectedEmailableOwners, emailableOwners]);
    }
  };

  return (
    <FilterContainer
      value="emailable-owner"
      title={
        <div className="text-heading-05 text-dark-dark">Emailable Owners</div>
      }
    >
      <div className="flex-col items-center justify-center space-y-2 px-6">
        {OPTIONS.map(({ value, label }) => (
          <CheckboxWithLabel
            key={value}
            id={`emailable-owner-${value}`}
            label={label}
            checked={selectedEmailableOwners.includes(value)}
            onClick={() => handleEmailableOwnersChange(value)}
          />
        ))}
      </div>
    </FilterContainer>
  );
};
