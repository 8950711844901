import { SalesFunnelStatsSection } from '@/pages/crm/sales-funnel/sales-funnel-stats-section';
import { FilterContainer } from '@/components/filters/sections/filter-container';
import { AggregateSumChart } from '@/pages/crm/sales-funnel/aggregate-sum-chart';
import { ConversionRateChart } from '@/components/charts/conversion-rate-chart/conversion-rate-chart';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/use-api';
import { SalesFunnelStatsResponse } from '@/pages/crm/sales-funnel/types';
import { useAuth } from '@/context';
import { UserListResponse } from '@/pages';
import { useEffect, useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { DropdownButtonIcon } from '@/components/map/property-details/icons/dropdown-button-icon';
import { CheckboxWithLabel } from '@/components/filters/sections/checkbox-with-label';
import { SalesFunnelChart } from '@/components';

export const SalesFunnelContainer = () => {
  const { getRequest } = useApi();
  const { userDetails } = useAuth();

  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(
    undefined
  );

  const [includeCurrentMonth, setIncludeCurrentMonth] = useState(
    userDetails.isSuperuser
  );

  const { data: userList } = useQuery<UserListResponse>({
    queryKey: ['usersData'],
    queryFn: () => getRequest('/api/users'),
    enabled: userDetails.isSuperuser,
  });

  const { data } = useQuery<SalesFunnelStatsResponse>({
    queryKey: ['sales-funnel-stats', selectedUserId, includeCurrentMonth],
    queryFn: () =>
      getRequest(
        `/api/stats/sales_funnel_stats${userDetails.isSuperuser && selectedUserId ? `?user_id=${selectedUserId}` : ''}${includeCurrentMonth ? '&include_current_month=true' : ''}`
      ),
  });

  useEffect(() => {
    if (userList?.data) {
      const user = userList?.data.find(
        (user) => user.email === userDetails.email
      );

      setSelectedUserId(user?.id);
    }
  }, [userDetails.email, userList?.data]);

  const currentUser = userList?.data.find((user) => user.id === selectedUserId);

  return (
    <div
      className="flex w-full space-x-5"
      style={{ height: 'calc(100vh - 108px)' }}
    >
      <div className="w-full space-y-6 h-full overflow-y-auto bg-gradient-to-b from-[#e5e7ee] to-[#e5e7ee] rounded-lg p-3">
        <div className="grid gap-4 grid-cols-1 lg:gap-8 xl:grid-cols-4">
          <SalesFunnelStatsSection data={data?.chartData} />
          <div className="flex flex-col xl:justify-end items-end space-y-4">
            {userList && (
              <>
                <CheckboxWithLabel
                  label="Include current month"
                  checked={includeCurrentMonth}
                  onClick={() => setIncludeCurrentMonth(!includeCurrentMonth)}
                />
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="blue">
                      <span>
                        {currentUser?.firstName} {currentUser?.lastName}
                      </span>
                      <span>
                        <DropdownButtonIcon />
                      </span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="min-w-48 bg-blue-tint text-blue-shade">
                    <DropdownMenuGroup>
                      {userList.data.map((user) => (
                        <DropdownMenuCheckboxItem
                          checked={user.id === selectedUserId}
                          key={user.id}
                          className="focus:text-blue-tint focus:bg-blue-blue"
                          onClick={() => setSelectedUserId(user.id)}
                        >
                          {user.firstName} {user.lastName}
                        </DropdownMenuCheckboxItem>
                      ))}
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              </>
            )}
          </div>
        </div>

        <div className="grid gap-4 grid-cols-1 lg:gap-8 xl:grid-cols-2">
          <div>
            <FilterContainer
              value="sales-funnel"
              triggerClassName="bg-light-light rounded-lg shadow"
              title={
                <div className="text-heading-05 text-dark-dark">LO Funnel</div>
              }
            >
              <div className="mt-6">
                <div className="w-full flex items-center justify-center h-[430px]">
                  <SalesFunnelChart data={data?.salesFunnel} />
                </div>
              </div>
            </FilterContainer>
          </div>
          <div>
            <FilterContainer
              value="conversion-rate"
              triggerClassName="bg-light-light rounded-lg shadow"
              title={
                <div className="text-heading-05 text-dark-dark">
                  Conversion Rate
                </div>
              }
            >
              <ConversionRateChart data={data?.chartData ?? []} />
            </FilterContainer>
          </div>
        </div>

        <div className="grid gap-4 grid-cols-1">
          <div>
            <FilterContainer
              value="aggregate-sum-of-refinanced-mortgage-balance"
              triggerClassName="bg-light-light rounded-lg shadow"
              title={
                <div className="text-heading-05 text-dark-dark">
                  Aggregate Sum of Refinanced Mortgage Balance
                </div>
              }
            >
              <AggregateSumChart data={data?.chartData ?? []} />
            </FilterContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
