import {
  EChartsOption,
  TooltipComponentFormatterCallbackParams,
} from 'echarts';
import { ECharts } from '@/components/charts/market-opportunity-chart/e-charts';

interface ConversionRateChartRow {
  date: string;
  leadFollowUp: number;
  leadRefinanced: number;
  leadRefinancedRatio: number;
}

interface ConversionRateChartProps {
  data: ConversionRateChartRow[];
}

export const ConversionRateChart = ({ data }: ConversionRateChartProps) => {
  const option: EChartsOption = {
    xAxis: {
      type: 'category',
      data: data.map((row) => row.date),
      axisLabel: {
        align: 'left',
      },
    },
    yAxis: [
      {
        type: 'value',
        name: 'Follow-Up Leads',
        position: 'left',
      },
      {
        type: 'value',
        name: 'Refinanced Leads (%)',
        position: 'right',
        axisLabel: {
          formatter: '{value}%',
        },
      },
    ],
    series: [
      {
        name: 'Follow-Up Leads',
        type: 'bar',
        data: data.map((row) => row.leadFollowUp),
        itemStyle: {
          color: '#FFA53C',
        },
      },
      {
        name: 'Refinanced Leads',
        type: 'line',
        yAxisIndex: 1,
        data: data.map((row) => row.leadRefinancedRatio * 100),
        itemStyle: {
          color: '#056B3A',
        },
        tooltip: {
          valueFormatter: (value) => `${value}%`,
        },
      },
    ],
    tooltip: {
      trigger: 'axis',
      formatter: (params: TooltipComponentFormatterCallbackParams) => {
        const tooltipParams = params as unknown as {
          axisValue: string;
          marker: string;
          seriesName: string;
          data: number;
        }[];
        let tooltipText = `${tooltipParams[0].axisValue}<br/>`;
        tooltipParams.forEach((item) => {
          const row = data.find((d) => d.date === item.axisValue);
          if (item.seriesName === 'Refinanced Leads') {
            tooltipText += `${item.marker} ${item.seriesName}: ${item.data.toFixed(2)}% (${row?.leadRefinanced})<br/>`;
          } else {
            tooltipText += `${item.marker} ${item.seriesName}: ${item.data}<br/>`;
          }
        });
        return tooltipText;
      },
    },
  };

  return (
    <div className="h-[400px] mt-6">
      {data.length === 0 ? (
        <div style={{ height: 450 }}>Loading...</div>
      ) : (
        <ECharts option={option} style={{ height: 450 }} />
      )}
    </div>
  );
};
